import axios from 'axios';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { URL_YOUTUBE_CALLBACK } from './api/routes';

const YoutubeRedirect = () => {
  const [cookies] = useCookies(['user']);
  const url = window.location.href;
  const code = url.split('?code=')[1].split('&scope=')[0];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
        };

        const response = await axios.get(
          `${URL_YOUTUBE_CALLBACK}${code}`,
          config
        );
        const { token } = response.data;

        if (token) {
          navigate('/profile');
        } else {
          console.error('Access token not received:', response.data);
        }
      } catch (error) {
        console.error('API call failed:', error);
      }
    };

    fetchAccessToken();
  }, [code, navigate, cookies.Bestofy_Token]);

  return <></>;
};

export default YoutubeRedirect;
