import React, { useState } from 'react';
import { Select } from '../../components';
import CustomFormController from './CustomFormController';
import './customForm.css';

const CustomFormHeader = ({ config }) => {
  return (
    <>
      <div className="bestofy__bar" />
      <span className="text-xl text-white-0 font-medium">
        {config.formTitle}
      </span>
      <div className="mt-10">
        <span className="text-tiny text-purple-1 mt-5">
          *Mandatory fields
        </span>
      </div>
    </>
  );
};

const CustomFormObject = ({ config, onChangeObject, selectedObject }) => {
  const { customContactObject } = config;
  const getObject = () => {
    const listObj = [];
    customContactObject.forEach(
      (element) => element.key !== '0' && listObj.push(element.label)
    );
    return listObj;
  };
  const listObject = getObject();

  return (
    <div className="my-8">
      <label>
        <span className="mt-4 text-tiny text-white-0 font-medium">
          Reason for contacting us
        </span>
        <span className="text-white-0 ml-3 font-semibold">*</span>
      </label>
      <Select
        className="focus:outline-none border-2 border-black-3 focus:bg-black-4 bg-black-3 w-full font-medium rounded-none lg:text-sm text-xs"
        options={listObject}
        selectedValue={selectedObject}
        onChange={(e) => onChangeObject(e.target.value)}
      />
    </div>
  );
};

const CustomForm = ({ config }) => {
  const { customContactObject } = config;

  const [selectedObject, setSelectedObject] = useState(
    customContactObject[0].label
  );
  const getSelectedObjectKey = (e) => {
    setSelectedObject(e);
  };
  return (
    <>
      <div className="flex justify-center">
        <div className="w-full lg:w-9/12 bg-black-2 py-8 px-4 lg:mb-16 lg:rounded-lg">
          <div className="text-r px-4 pt-3">
            <CustomFormHeader config={config} />
            <CustomFormObject
              config={config}
              onChangeObject={getSelectedObjectKey}
              selectedObject={selectedObject}
            />
            <CustomFormController
              config={config}
              selectedObject={selectedObject}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomForm;
