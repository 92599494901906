import { useEffect } from 'react';
import { Navbar } from '../components';
import { CustomForm, FooterLinks } from '../containers';
import JSONconfig from '../assets/data/CustomFormConfig.json';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg bg-pu">
      <Navbar />
      <div className="pt-28">
        <CustomForm config={JSONconfig.config} />
      </div>
      <FooterLinks />
    </div>
  );
};

export default Contact;
