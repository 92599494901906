import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { Button, Navbar, RessourceGallery, Timeline } from '../../components';
import { FooterLinks } from '../../containers';
import { URL_CLIPS, URL_PROCESS } from '../api/routes';
import { BsPhone, BsQuestionCircle } from 'react-icons/bs';
import { CiYoutube } from 'react-icons/ci';
import axios from 'axios';
import './videoEditing.css';
import { useForm } from 'react-hook-form';

const Transition = Array.from({ length: 6 }, (v, i) => ({
  id: i + 1,
  name: ['fade', 'slide', 'zoom', 'rotate', 'flip', 'bounce'][i],
  status: 'purple',
  type: 'transition',
}));

const NumberOfClipsInput = ({ register, updatedField, onChange }) => (
  <div className="relative w-full h-10 mb-5 md:mb-0">
    {updatedField === 'videoLength' && (
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          <span className="text-xs font-medium text-purple-1">≈</span>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <span className="text-xs font-medium text-purple-1">clips</span>
        </div>
      </>
    )}
    <input
      type="number"
      placeholder="Number of Clips"
      {...register('numberOfClips')}
      min="1"
      onChange={onChange}
      className={`w-full h-10 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-md text-xs font-medium text-purple-1 px-2 ${
        updatedField === 'videoLength' ? 'pl-6' : 'pl-2'
      }`}
    />
  </div>
);

const VideoLengthInput = ({ register, updatedField, onChange }) => (
  <div className="relative w-full h-10 mb-5 md:mb-0">
    {updatedField === 'numberOfClips' && (
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          <span className="text-xs font-medium text-purple-1">≈</span>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <span className="text-xs font-medium text-purple-1">minutes</span>
        </div>
      </>
    )}
    <input
      type="number"
      placeholder="Video Length (min)"
      {...register('videoLength')}
      min="1"
      step="1"
      onChange={onChange}
      className={`w-full h-10 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-md text-xs font-medium text-purple-1 px-2 ${
        updatedField === 'numberOfClips' ? 'pl-6' : 'pl-2'
      }`}
    />
  </div>
);

const InputsClip = ({
  subtitlesEnabled,
  toggleSubtitles,
  numberOfClips,
  setNumberOfClips,
  updateNumberOfClips,
}) => {
  const [updatedField, setUpdatedField] = useState('');
  const { register, setValue } = useForm();

  const defaultNumberOfClips = 10;
  const calculateVideoLength = (numOfClips) => Math.round(numOfClips * 0.4);

  useEffect(() => {
    setValue('numberOfClips', defaultNumberOfClips);
    setValue('videoLength', calculateVideoLength(defaultNumberOfClips));
  }, [setValue]);

  const handleNumberOfClipsChange = (e) => {
    const numOfClips = parseInt(e.target.value);
    if (!isNaN(numOfClips)) {
      setNumberOfClips(numOfClips); // Update the number of clips
      setValue('videoLength', calculateVideoLength(numOfClips));
      setUpdatedField('numberOfClips');
    }
  };

  const handleVideoLengthChange = (e) => {
    const length = parseInt(e.target.value);
    if (!isNaN(length)) {
      const newNumberOfClips = Math.round(length / 0.4);
      setValue('numberOfClips', newNumberOfClips, { shouldValidate: true });
      updateNumberOfClips(newNumberOfClips); // Call the passed callback
      setUpdatedField('videoLength');
    }
  };
  return (
    <form>
      <div className="w-full">
        <label
          htmlFor="numberOfClips"
          className="block text-xs font-medium text-white-0 mb-2"
        >
          Number of Clips
        </label>
        <NumberOfClipsInput
          register={register}
          updatedField={updatedField}
          onChange={handleNumberOfClipsChange}
        />
      </div>

      <div className="w-full mt-2">
        <label
          htmlFor="videoLength"
          className="block text-xs font-medium text-white-0 mb-2"
        >
          Video Length in minutes
        </label>
        <VideoLengthInput
          register={register}
          updatedField={updatedField}
          onChange={handleVideoLengthChange}
        />
      </div>
      <div className="flex items-center mt-4">
        <span className="text-white-4 text-xs mr-4">Add subtitle</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={subtitlesEnabled}
            onChange={toggleSubtitles}
          />
          <div className="w-11 h-6 bg-black-3 peer-focus:ring-purple-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white-1 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-1 after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-1"></div>
        </label>
      </div>
    </form>
  );
};

const CustomFileInput = ({ setValue, accept, name, buttonLabel }) => {
  const [fileName, setFileName] = useState('');

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setValue(name, file);
    } else {
      setFileName('');
    }
  };

  return (
    <div className="w-full flex items-center mb-5 md:mb-0">
      <input
        type="file"
        accept={accept}
        id={name}
        className="hidden"
        onChange={handleChange}
      />
      <label
        htmlFor={name}
        className="w-auto h-10 px-6 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2 text-xs text-white-0 cursor-pointer flex items-center justify-center"
      >
        {buttonLabel}
      </label>
      <div className="ml-4 text-xs font-medium text-purple-1">
        {fileName || 'No file chosen'}
      </div>
    </div>
  );
};

const VideoEditingPanel = ({ config }) => {
  const [placeholder, setPlaceholder] = useState({ id: 0 });
  const [timelineVideos, setTimelineVideos] = useState([]);
  const [videoDisplay, setVideoDisplay] = useState([]);
  const [numberOfClips, setNumberOfClips] = useState(10);
  const updateNumberOfClips = (newNumberOfClips) => {
    setNumberOfClips(newNumberOfClips);
  };
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const videoContainerRef = useRef(null);
  const [videoContainerHeight, setVideoContainerHeight] = useState(0);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (videoContainerRef.current) {
      setVideoContainerHeight(videoContainerRef.current.offsetHeight);
    }
  }, [videoContainerRef]);

  useEffect(() => {
    async function getClips() {
      return await axios
        .get(`${URL_CLIPS}?user=FrostBloody&number=${numberOfClips}`, config)
        .then((response) => {
          const updatedData = response.data.data
            .map((item) => ({ ...item, type: 'clip' }))
            .flatMap((item) => [
              item,
              Transition[Math.floor(Math.random() * 6)],
            ]);
          setTimelineVideos(updatedData);
        })
        .catch(console.log);
    }
    getClips();
  }, [config, numberOfClips]);

  const updateHeight = () => {
    if (videoContainerRef.current) {
      setVideoContainerHeight(videoContainerRef.current.offsetHeight);
    }
  };

  const [timeline, setTimeline] = useState([]);
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const [subtitlesEnabled, setSubtitlesEnabled] = useState(false);
  const [IAEnabled, setIAEnabled] = useState(false);

  async function generateVideo(data) {
    const clips = timeline.filter((item) => item.type === 'clip');
    const transition = timeline.filter((item) => item.type === 'transition');

    const clipsId = clips.map((clip) => clip.thumbnail_url);
    const transitionName = transition.map((transition) => transition.name);
    const formData = new FormData();

    formData.append('transition', JSON.stringify(transitionName));
    formData.append('videos', JSON.stringify(clipsId));
    formData.append('title', data.title || '');
    formData.append('subtitle', subtitlesEnabled || false);
    formData.append('ia', IAEnabled || false);
    formData.append('description', data.description || '');
    if (data.intro)
      formData.append('intro', data.intro, {
        filename: data.intro.name,
      });
    if (data.outro)
      formData.append('outro', data.outro, {
        filename: data.outro.name,
      });
    if (data.logo) formData.append('logo', data.logo, data.logo.name);
    try {
      const response = await axios.post(URL_PROCESS, formData, {
        headers: {
          Authorization: `Bearer ${cookies.Bestofy_Token}`,
        },
      });
      if (response.status === 200) {
        navigate('/playground/project');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toggleSubtitles = () => {
    setSubtitlesEnabled((prevState) => !prevState);
  };

  const toggleIA = () => {
    setIAEnabled((prevState) => !prevState);
  };

  const onSubmit = (data) => {
    let hasError = false;
    if (!data.title || data.title.trim() === '') {
      setTitleError('Video Title is required.');
      hasError = true;
    } else {
      setTitleError('');
    }

    // Check if description is empty
    if (!data.description || data.description.trim() === '') {
      setDescriptionError('Video Description is required.');
      hasError = true;
    } else {
      setDescriptionError('');
    }

    // If there are errors, do not proceed further
    if (hasError) {
      return;
    }
    const isValidTimeline = timeline.every((item, index) => {
      if (item.type === 'placeholder') {
        return true;
      } else if (index === 0) {
        return item.type === 'clip';
      } else {
        const prevItem = timeline[index - 1];
        if (prevItem.type === item.type) {
          return false;
        }
        return true;
      }
    });

    if (isValidTimeline) {
      console.log('Timeline is valid');
      console.log(data)
      generateVideo(data);
    } else {
      console.log('Invalid timeline');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-10 gap-6 mt-10">
          <div className="col-span-10 bg-black-2 rounded-lg p-4">
            <label
              htmlFor="title"
              className="block text-xs font-medium text-white-0 mb-2"
            >
              Video Title *
            </label>
            <input
              type="text"
              placeholder="Enter video title"
              {...register('title')}
              className="w-full border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2"
            />
            {titleError && (
              <p className="text-red-500 mt-2 text-xs italic">{titleError}</p>
            )}
          </div>
          <div
            className="col-span-3 bg-black-2 rounded-lg"
            style={{ maxHeight: `${videoContainerHeight}px` }}
          >
            <RessourceGallery
              setPlaceholder={setPlaceholder}
              config={config}
              setVideoDisplay={setVideoDisplay}
            />
          </div>
          <div
            className="col-span-7 bg-black-2 rounded-lg flex justify-center items-center"
            ref={videoContainerRef}
          >
            {videoDisplay.length > 0 ? (
              <video
                className="max-w-full max-h-full rounded-lg"
                src={videoDisplay}
                controls
                onLoadedMetadata={updateHeight}
              ></video>
            ) : (
              <div className="flex flex-col justify-center items-center h-96">
                <CiYoutube size={100} className="text-purple-2" />
                <p className="text-center mt-4 font-semibold text-white-1">
                  Select a clip to preview it
                </p>
              </div>
            )}
          </div>
          <div className="col-span-3 bg-black-2 rounded-lg">
            <div className="p-4">
              <InputsClip
                subtitlesEnabled={subtitlesEnabled}
                toggleSubtitles={toggleSubtitles}
                numberOfClips={numberOfClips}
                setNumberOfClips={setNumberOfClips}
                updateNumberOfClips={updateNumberOfClips}
              />
            </div>
          </div>
          <div className="col-span-7 col-start-4 bg-black-2 rounded-lg">
            {timelineVideos.length > 0 && (
              <Timeline
                setPlaceholder={setPlaceholder}
                placeholder={placeholder}
                timelineVideos={timelineVideos}
                config={config}
                setTimeline={setTimeline}
                timeline={timeline}
                IAEnabled={IAEnabled}
                toggleIA={toggleIA}
              />
            )}
          </div>
          <div className="col-span-10 bg-black-2 rounded-lg p-4">
            <label
              htmlFor="description"
              className="block text-xs font-medium text-white-0 mb-2"
            >
              Video Description *
            </label>
            <textarea
              placeholder="Enter video description"
              {...register('description')}
              className="w-full h-40 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2"
            />
            {descriptionError && (
              <p className="text-red-500 mt-2 text-xs italic">{descriptionError}</p>
            )}
          </div>
          <div className="col-span-10 bg-black-2 rounded-lg p-4">
            <label
              htmlFor="description"
              className="block text-xs font-medium text-white-0 mb-4"
            >
              Additional settings
            </label>
            <div className="flex flex-col md:flex-row md:space-x-2 mb-2">
              <CustomFileInput
                setValue={setValue}
                accept="image/*"
                name="logo"
                buttonLabel="Logo"
              />

              <CustomFileInput
                setValue={setValue}
                accept="video/mp4"
                name="intro"
                buttonLabel="Video Intro"
              />

              <CustomFileInput
                setValue={setValue}
                accept="video/mp4"
                name="outro"
                buttonLabel="Video Outro"
              />
            </div>
          </div>
        </div>
        <div className="pt-16 flex items-center justify-center">
          <Button
            type="submit"
            className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
          >
            Generate
          </Button>
        </div>
      </form>
    </div>
  );
};

const VideoEditing = () => {
  const [cookies] = useCookies(['user']);
  const config = {
    headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
  };

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <div className="flex items-center">
                <span className="text-xl text-white-0 font-medium">
                  Video Editing
                </span>
                <div className="tooltip ml-2">
                  <BsQuestionCircle size={16} className="text-purple-2 mt-1" />
                  <div className="tooltiptext text-white-6">
                    Explore the Video Editing panel to enhance your video
                    montage. Select desired transitions, arrange clips, and add
                    subtitles. Don't forget to provide a title and description
                    for your video. Click 'Generate' once you're ready to
                    finalize your creation. Happy editing!
                  </div>
                </div>
              </div>
              <div className="hidden md:contents">
                <VideoEditingPanel config={config} />
              </div>
              <div className="md:hidden">
                <div className="my-16">
                  <BsPhone
                    size={100}
                    className="mx-auto fill-purple-2 rotate"
                  />
                  <h1 className="text-center mt-4 font-semibold text-white-6 leading-snug">
                    Flip your phone
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default VideoEditing;
