import React from 'react';
import { useEffect } from 'react';
import { FiX } from 'react-icons/fi';

const Timeline = ({
  setPlaceholder,
  placeholder,
  timelineVideos,
  videoData,
  config,
  setTimeline,
  timeline,
  IAEnabled,
  toggleIA,
}) => {
  useEffect(() => {
    setTimeline(timelineVideos);
    const newTransitionArray = [];

    timelineVideos.forEach((item) => {
      if (item.type === 'clip') {
      } else if (item.type === 'transition') {
        newTransitionArray.push(item.name);
      }
    });
  }, [setTimeline, timelineVideos]);

  const removeItem = (index) => {
    setTimeline((prevItems) => {
      const items = [...prevItems];
      items.splice(index, 1);
      return items;
    });
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    setTimeline((prevItems) => {
      const items = [...prevItems];
      const placeholderIndex = items.findIndex(
        (item) => item.type === 'placeholder'
      );

      if (placeholderIndex !== -1) {
        items.splice(placeholderIndex, 1);
      }
      items.splice(index, 0, placeholder);

      return items;
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const draggedItem = JSON.parse(event.dataTransfer.getData('item'));

    setTimeline((prevDroppedItems) => {
      const items = [...prevDroppedItems];
      const index = items.findIndex((item) => item.type === 'placeholder');
      if (index !== -1) {
        items[index] = draggedItem;
      }

      return items;
    });
  };

  return (
    <div className="p-4 mt-2">
      <div className="flex justify-end items-center">
        <span className="text-white-4 text-xs mr-4">
          Advanced AI features
        </span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={IAEnabled}
            onChange={toggleIA}
          />
          <div className="w-11 h-6 bg-black-3 peer-focus:ring-purple-2 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white-1 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white-1 after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-1"></div>
        </label>
      </div>
      <div className="flex h-fit rounded overflow-x-auto overflow-hidden scrollbar mt-4">
        {timeline.map((item, index) => (
          <div key={index}>
            <div className="mx-2 relative">
              {item.type === 'placeholder' ? (
                <div
                  className={`h-28 my-2 border border-purple-1 rounded w-32`}
                  onDrop={(event) => handleDrop(event, item, index)}
                  onDragOver={(event) => handleDragOver(event, index)}
                  draggable="true"
                ></div>
              ) : item.type === 'clip' ? (
                <div
                  className="h-28 w-48 text-white-0 my-2 rounded flex justify-center items-center"
                  onDrop={(event) => handleDrop(event, item, index)}
                  onDragOver={(event) => handleDragOver(event, index)}
                >
                  <img
                    className="overflow-hidden h-full rounded w-full"
                    src={item.thumbnail_url}
                    alt=""
                  />
                  <FiX
                    size={20}
                    className="absolute -top-2 -right-2 cursor-pointer bg-purple-1 rounded-full p-0.5 hover:bg-purple-2"
                    onClick={() => removeItem(index)}
                  />
                </div>
              ) : (
                <div
                  className="w-20 h-28 text-white-0 my-2 rounded bg-black-3 flex justify-center items-center"
                  onDrop={(event) => handleDrop(event, item, index)}
                  onDragOver={(event) => handleDragOver(event, index)}
                >
                  <p className="capitalize">{item.name}</p>
                  <FiX
                    size={20}
                    className="absolute -top-2 -right-2 cursor-pointer bg-bl bg-purple-1 rounded-full p-0.5 hover:bg-purple-2"
                    onClick={() => removeItem(index)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
