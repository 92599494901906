import React from 'react';
import { useNavigate } from 'react-router-dom';

const PricingDetailsText = ({ title, textFree, textBasic, textPro }) => {
  return (
    <tr>
      <th scope="row" className="text-left">
        <h3 className="py-3">{title}</h3>
      </th>
      <td>
        <span className="block text-sm">{textFree}</span>
      </td>
      <td>
        <span className="block text-sm">{textBasic}</span>
      </td>
      <td>
        <span className="block text-sm">{textPro}</span>
      </td>
    </tr>
  );
};

const NotIncluded = () => {
  return (
    <td>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-label="Not included in Free plan"
        className="w-5 h-5 mx-auto text-black-4"
      >
        <path
          fillRule="evenodd"
          d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
    </td>
  );
};

const IsIncluded = () => {
  return (
    <td>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-label="Included in Standard plan"
        className="w-5 h-5 mx-auto text-purple-1"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    </td>
  );
};

const PricingDetailsIncluded = ({
  title,
  includedFree,
  includedBasic,
  includedPro,
}) => {
  return (
    <tr>
      <th scope="row" className="text-left">
        <h3 className="py-3">{title}</h3>
      </th>
      {includedFree ? <IsIncluded /> : <NotIncluded />}
      {includedBasic ? <IsIncluded /> : <NotIncluded />}
      {includedPro ? <IsIncluded /> : <NotIncluded />}
    </tr>
  );
};

const MoreDetailsLinks = () => {
  const navigate = useNavigate();
  return (
    <tr>
      <th scope="row" className="text-left">
        <h3 className="py-3">Dive deeper into our plans</h3>
      </th>
      {
        <td
          className="text-xs text-white-4 mt-4 decoration-white-6 decoration-2 underline underline-offset-2 hover:text-purple-1 hover:decoration-purple-1 cursor-pointer"
          onClick={() => navigate('/articles/free-tier')}
        >
          More info
        </td>
      }
      {
        <td
          className="text-xs text-white-4 mt-4 decoration-white-6 decoration-2 underline underline-offset-2 hover:text-purple-1 hover:decoration-purple-1 cursor-pointer"
          onClick={() => navigate('/articles/basic-tier')}
        >
          More info
        </td>
      }
      {
        <td
          className="text-xs text-white-4 mt-4 decoration-white-6 decoration-2 underline underline-offset-2 hover:text-purple-1 hover:decoration-purple-1 cursor-pointer"
          onClick={() => navigate('/articles/pro-tier')}
        >
          More info
        </td>
      }
    </tr>
  );
};

const PrincingDetailsType = ({
  typeName,
  priceMonth,
  priceYear,
  pricingType,
}) => {
  return (
    <th scope="col">
      <h2 className="px-2 text-md sm:text-lg font-bold">{typeName}</h2>
      <p className="mb-3 mt-2">
        <span className="font-bold sm:text-4xl text-sm text-white-6">
          {'$'}
          {pricingType === 'monthly' ? priceMonth : priceYear}
        </span>
        <span className="ml-1 font-normal text-tiny sm:text-xs text-black-4">
          {pricingType === 'monthly' ? '/ month' : '/ year'}
        </span>
      </p>
    </th>
  );
};

const PricingDetails = ({ pricingType }) => {
  return (
    <section className="mt-24 bg-black-1 text-white-1 rounded-lg">
      <div className="container mx-auto p-6 overflow-x-auto">
        <table className="w-full">
          <caption className="sr-only">Pricing plan comparison</caption>
          <thead>
            <tr>
              <th></th>
              <PrincingDetailsType
                typeName={'Free'}
                priceMonth={'0'}
                priceYear={'0'}
                pricingType={pricingType}
              />
              <PrincingDetailsType
                typeName={'Basic'}
                priceMonth={'15'}
                priceYear={'160'}
                pricingType={pricingType}
              />
              <PrincingDetailsType
                typeName={'Pro'}
                priceMonth={'35'}
                priceYear={'399'}
                pricingType={pricingType}
              />
            </tr>
          </thead>
          <tbody className="space-y-6 text-center divide-y divide-black-4">
            <PricingDetailsText
              title={'Video per month'}
              textFree={'1'}
              textBasic={'4'}
              textPro={'Unlimited'}
            />
            <PricingDetailsIncluded
              title={'Automatic upload on YouTube'}
              includedFree={true}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Choose the upload date'}
              includedFree={true}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Subtitle'}
              includedFree={true}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Choose your clips'}
              includedFree={false}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Access to all transitions'}
              includedFree={false}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'All features of the artifical intelligence'}
              includedFree={false}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Special effect'}
              includedFree={false}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Edit generated videos'}
              includedFree={false}
              includedBasic={true}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Automatic publication of Shorts'}
              includedFree={false}
              includedBasic={false}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Automatic publication on TikTok'}
              includedFree={false}
              includedBasic={false}
              includedPro={true}
            />
            <PricingDetailsIncluded
              title={'Automatic publication of Reels'}
              includedFree={false}
              includedBasic={false}
              includedPro={true}
            />
            <MoreDetailsLinks />
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PricingDetails;
