import axios from 'axios';
import { useState } from 'react';
import { SiTiktok, SiInstagram, SiYoutube, SiTwitter } from 'react-icons/si';
import { Button } from '..';
import {
  URL_TIKTOK_REDIRECT,
  URL_YOUTUBE_REDIRECT,
  URL_YOUTUBE_REVOKE,
} from '../../pages/api/routes';
import { useCookies } from 'react-cookie';

const DividerHorizontal = () => {
  return (
    <div className="col-span-12 bg-purple-1 h-0.5 my-8 rounded-l-lg rounded-r-lg" />
  );
};

// Oauth component with added `isLinked` prop
const Oauth = ({ logo, name, isLinked, onLink }) => {
  const [cookies] = useCookies(['user']);
  const [unlinkedConfirm, setUnlinkedConfirm] = useState(false);

  const handleUnlinkConfirm = () => {
    setUnlinkedConfirm(true);
  };

  const handleUnlinkCancel = () => {
    setUnlinkedConfirm(false);
  };

  const handleUnlink = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      const response = await axios.post(URL_YOUTUBE_REVOKE, {}, config);
      if (response.status === 200) {
        console.log('Successfully unlinked Youtube account');
        setUnlinkedConfirm(false);
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to unlink Youtube account', error);
    }
  };

  return (
    <>
      <div className="col-span-12 sm:col-span-3 flex justify-center">
        {logo}
      </div>
      <div className="col-span-12 sm:col-span-6">
        <p className="text-sm sm:text-md text-white-1 mt-2 sm:mt-0 font-medium text-center sm:text-left">
          Link your <span className="capitalize">{name}</span> account
        </p>
      </div>
      <div className="col-span-12 sm:col-span-3 text-center mt-2 sm:mt-0">
        {isLinked ? (
          unlinkedConfirm ? (
            <>
              <p className="text-white-1 font-light text-xs mb-2">
                Unlink your account?
              </p>
              <button
                onClick={handleUnlink}
                className="text-white-1 font-light hover:underline"
              >
                Yes
              </button>
              <span className="text-white-1 font-light"> / </span>
              <button
                onClick={handleUnlinkCancel}
                className="text-white-1 font-light hover:underline"
              >
                No
              </button>
            </>
          ) : (
            <button
              onClick={handleUnlinkConfirm}
              className="text-white-1 font-semibold"
            >
              &#x2715;{' '}
            </button>
          )
        ) : (
          <Button
            type="submit"
            variant="text"
            className="px-8 py-2 font-semibold rounded-full hover:text-purple-2"
            onClick={() => onLink(name)}
          >
            Link
          </Button>
        )}
      </div>
    </>
  );
};

// OauthList component
const OauthList = ({ oauthStatuses }) => {
  const handleLink = async (name) => {
    if (name === 'youtube') {
      try {
        const response = await axios.get(URL_YOUTUBE_REDIRECT);
        const { link } = response.data;

        // Redirect to YouTube OAuth
        window.location.href = link;
      } catch (error) {
        console.error('Failed to fetch YouTube OAuth URL', error);
      }
    } else {
      // Your linking logic for other platforms
      console.log(`Linking ${name}`);
    }
    if (name === 'tiktok') {
      try {
        const response = await axios.get(URL_TIKTOK_REDIRECT);
        const { link } = response.data;

        // Redirect to YouTube OAuth
        window.location.href = link;
      } catch (error) {
        console.error('Failed to fetch YouTube OAuth URL', error);
      }
    } else {
      // Your linking logic for other platforms
      console.log(`Linking ${name}`);
    }
  };

  return (
    <div className="mt-16 mb-12 w-full sm:w-3/4 m-auto">
      <div className="items-center grid grid-cols-12 gap-1">
        {Object.keys(oauthStatuses).map((name, index) => (
          <>
            <Oauth
              name={name}
              isLinked={oauthStatuses[name]}
              onLink={handleLink}
              logo={getIcon(name)}
            />
            {index < 3 && <DividerHorizontal />}
          </>
        ))}
      </div>
    </div>
  );
};

const getIcon = (name) => {
  const iconSize = 55; // default size
  const iconSizeMobile = 35; // smaller size for mobile
  const iconColor = '#ffffff';

  switch (name) {
    case 'youtube':
      return (
        <>
          <SiYoutube
            color={iconColor}
            size={iconSizeMobile}
            className="sm:hidden"
          />
          <SiYoutube
            color={iconColor}
            size={iconSize}
            className="hidden sm:block"
          />
        </>
      );
    case 'tiktok':
      return (
        <>
          <SiTiktok
            color={iconColor}
            size={iconSizeMobile}
            className="sm:hidden"
          />
          <SiTiktok
            color={iconColor}
            size={iconSize}
            className="hidden sm:block"
          />
        </>
      );
    case 'instagram':
      return (
        <>
          <SiInstagram
            color={iconColor}
            size={iconSizeMobile}
            className="sm:hidden"
          />
          <SiInstagram
            color={iconColor}
            size={iconSize}
            className="hidden sm:block"
          />
        </>
      );
    case 'twitter':
      return (
        <>
          <SiTwitter
            color={iconColor}
            size={iconSizeMobile}
            className="sm:hidden"
          />
          <SiTwitter
            color={iconColor}
            size={iconSize}
            className="hidden sm:block"
          />
        </>
      );
    default:
      return null;
  }
};


export default OauthList;
