const PREFIX_URL = 'https://api.bestofy.fr/api';

export const URL_CONFIDENTIALITY = PREFIX_URL + '/confidentiality';
export const URL_TERMS = PREFIX_URL + '/services';
export const URL_TWITCH_REDIRECT = PREFIX_URL + '/auths/twitch/redirect';
export const URL_TWITCH_CALLBACK = PREFIX_URL + '/auths/twitch/callback?code=';
export const URL_YOUTUBE_REDIRECT = PREFIX_URL + '/auths/youtube/redirect';
export const URL_YOUTUBE_CALLBACK =
  PREFIX_URL + '/auths/youtube/callback?code=';
export const URL_TIKTOK_REDIRECT = PREFIX_URL + '/auths/tiktok/redirect';
export const URL_TIKTOK_CALLBACK = PREFIX_URL + '/auths/tiktok/callback?code=';
export const URL_YOUTUBE_REVOKE = PREFIX_URL + '/auths/youtube/revoke';
export const URL_USER_INFO = PREFIX_URL + '/user/me';
export const URL_USER_INVOICES = PREFIX_URL + '/user/invoices';
export const URL_USER_SUBSCRIPTION = PREFIX_URL + '/user/subscription';
export const URL_PAYMENT = PREFIX_URL + '/payment';
export const URL_SEND_MAIL_ACKNOWLEDGMENT =
  PREFIX_URL + '/mail/sendAcknowledgment';
export const URL_SEND_MAIL = PREFIX_URL + '/mail';
export const URL_PROCESS = PREFIX_URL + '/process';
export const URL_CLIPS = PREFIX_URL + '/clips';
export const URL_PROJECTS = PREFIX_URL + '/s3';
export const URL_STATUS = PREFIX_URL + '/status';
export const URL_YOUTUBE_UPLOAD = PREFIX_URL + '/upload/youtube';
export const URL_AUTOMATE = PREFIX_URL + '/cron';
export const URL_NOTIFICATION_ENABLE = PREFIX_URL + '/user/notification/enable';
export const URL_NOTIFICATION_DISABLE =
  PREFIX_URL + '/user/notification/disable';
export const URL_NOTIFICATION = PREFIX_URL + '/user/notification';
