import { useCookies } from 'react-cookie';
import { Button } from '..';
import axios from 'axios';
import './pricing.css';
import { URL_PAYMENT } from '../../pages/api/routes';
import { useNavigate } from 'react-router-dom';

const SwitchMonthYear = ({ setPricingType }) => {
  return (
    <div
      className="flex justify-center mb-5 lg:mb-8"
      onChange={(e) => setPricingType(e.target.value)}
    >
      <ul className="radio-switch js-p-table__switch">
        <li className="radio-switch__item">
          <input
            className="radio-switch__input sr-only"
            type="radio"
            name="p-table-switch-name"
            id="p-table-switch-1"
            value="monthly"
            defaultChecked
          />
          <label className="radio-switch__label" htmlFor="p-table-switch-1">
            Monthly
          </label>
        </li>

        <li className="radio-switch__item">
          <input
            className="radio-switch__input sr-only"
            type="radio"
            name="p-table-switch-name"
            id="p-table-switch-2"
            value="yearly"
          />
          <label className="radio-switch__label" htmlFor="p-table-switch-2">
            Yearly
          </label>
          <div aria-hidden="true" className="radio-switch__marker"></div>
        </li>
      </ul>
    </div>
  );
};

const PricingCards = ({ pricingType, onClickSignIn }) => {
  const [cookies] = useCookies(['user']);
  const navigate = useNavigate();

  async function onClickSubscribe(productId) {
    const config = {
      headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
    };
    const bodyParameters = {
      quantity: 1,
    };

    return await axios
      .post(URL_PAYMENT + `/${productId}`, bodyParameters, config)
      .then(function (response) {
        window.open(
          response.data.clientSecret.hosted_invoice_url,
          'targetWindow',
          `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=600,
         height=600`
        );
        navigate('/pending');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="grid grid-cols-12 gap-3 lg:gap-10">
      <div className="flex flex-col bg-black-5 rounded-2xl shadow-inner p-5 lg:p-8 col-span-12 lg:col-span-4 mb-4 mt-4 lg:mt-0 lg:mb-0">
        <div className="mb-1.5 lg:mb-2">
          <h4 className="text-white-1 text-lg font-bold">Free</h4>
        </div>
        <div className="p-table__price-wrapper mb-3 lg:mb-5">
          {pricingType === 'monthly' ? (
            <div className="p-table__price slide-in-elliptic-top-fwd-month">
              <span>$0</span> <i className="not-italic">/ month</i>
            </div>
          ) : (
            <div className="p-table__price slide-in-elliptic-top-fwd-year">
              <span>$0</span> <i className="not-italic">/ year</i>
            </div>
          )}
        </div>

        <ul className="p-table__features mb-5 lg:mb-8">
          <li>1 video per month</li>
          <li>Automatic upload on YouTube</li>
          <li>Free 15/days trial</li>
        </ul>

        <div className="mt-auto">
          <div className="flex justify-center">
            <Button
              type="submit"
              className="bg-black-7 hover:bg-black-6 font-light text-md rounded-full py-2 w-full"
              disabled={localStorage.getItem('Bestofy_Subscribed') === 'true'}
              onClick={
                cookies.Bestofy_Token
                  ? () => onClickSubscribe('prod_N86lOi1U0dIHTD')
                  : onClickSignIn
              }
            >
              {cookies.Bestofy_Token
                ? localStorage.getItem('Bestofy_Subscribed') === 'true'
                  ? 'Already Subscribed'
                  : 'Subscribe'
                : 'Sign up'}
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-2xl shadow-inner p-5 lg:p-8 bg-black-6 col-span-12 lg:col-span-4 border-4 border-purple-1 mb-4 lg:mb-0">
        <div className="flex justify-between items-center mb-1.5 lg:mb-2">
          <h4 className="text-white-1 text-lg font-bold">Basic</h4>
          <span className="p-table__badge">Popular</span>
        </div>

        <div className="p-table__price-wrapper mb-3 lg:mb-5">
          {pricingType === 'monthly' ? (
            <div className="p-table__price slide-in-elliptic-top-fwd-month">
              <span>$15</span> <i className="not-italic">/ month</i>
            </div>
          ) : (
            <div className="p-table__price slide-in-elliptic-top-fwd-year">
              <span>$160</span> <i className="not-italic">/ year</i>
            </div>
          )}
        </div>

        <ul className="p-table__features mb-5 lg:mb-8">
          <li>4 videos per month</li>
          <li>Automatic upload on YouTube</li>
          <li>Edit generated videos</li>
          <li>Artificial intelligence</li>
        </ul>

        <div className="mt-auto">
          <div className="flex justify-center">
            <Button
              type="submit"
              className="bg-purple-1 hover:bg-purple-2 font-light text-md rounded-full py-2 w-full"
              disabled={localStorage.getItem('Bestofy_Subscribed') === 'true'}
              onClick={
                cookies.Bestofy_Token
                  ? () => onClickSubscribe('prod_N86jCeIHagcC0P')
                  : onClickSignIn
              }
            >
              {cookies.Bestofy_Token
                ? localStorage.getItem('Bestofy_Subscribed') === 'true'
                  ? 'Already Subscribed'
                  : 'Subscribe'
                : 'Sign up'}
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-black-5 rounded-2xl shadow-inner p-5 lg:p-8 col-span-12 lg:col-span-4">
        <div className="mb-1.5 lg:mb-2">
          <h4 className="text-white-1 text-lg font-bold">Pro</h4>
        </div>
        <div className="p-table__price-wrapper mb-3 lg:mb-5">
          {pricingType === 'monthly' ? (
            <div className="p-table__price slide-in-elliptic-top-fwd-month">
              <span>$35</span> <i className="not-italic">/ month</i>
            </div>
          ) : (
            <div className="p-table__price slide-in-elliptic-top-fwd-year">
              <span>$399</span> <i className="not-italic">/ year</i>
            </div>
          )}
        </div>

        <ul className="p-table__features mb-5 lg:mb-8">
          <li>Unlimited videos</li>
          <li>Access to all features</li>
          <li>Edit generated videos</li>
          <li>Artificial intelligence</li>
          <li>Shorts / TikTok / Reels</li>
        </ul>

        <div className="mt-auto">
          <div className="flex justify-center">
            <Button
              type="submit"
              className="bg-black-7 hover:bg-black-6 font-light text-md rounded-full py-2 w-full"
              disabled={localStorage.getItem('Bestofy_Subscribed') === 'true'}
              onClick={
                cookies.Bestofy_Token
                  ? () => onClickSubscribe('prod_N86jPDJ1wgQnqQ')
                  : onClickSignIn
              }
            >
              {cookies.Bestofy_Token
                ? localStorage.getItem('Bestofy_Subscribed') === 'true'
                  ? 'Already Subscribed'
                  : 'Subscribe'
                : 'Sign up'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingTable = ({ pricingType, setPricingType, onClickSignIn }) => {
  return (
    <div className="p-table p-table--has-switch js-p-table--has-switch">
      <SwitchMonthYear setPricingType={setPricingType} />
      <PricingCards pricingType={pricingType} onClickSignIn={onClickSignIn} />
    </div>
  );
};

export default PricingTable;
