import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components';
import './pages.css';
import './spinner.css';
import { URL_USER_SUBSCRIPTION } from './api/routes';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const Pending = () => {
  const [cookies] = useCookies(['user']);
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [setTime]);

  async function checkSubscription() {
    const config = {
      headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
    };
    return await axios
      .get(URL_USER_SUBSCRIPTION, config)
      .then(function (response) {
        setPending(response.data?.subscription?.pending);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  checkSubscription();

  return (
    <>
      <section className="flex items-center justify-center h-screen w-screen p-12 gradient__bg">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <input type="checkbox" id="check" checked={!pending} name="spinner" />
          <label for="check" name="spinner">
            <div className="check-icon"></div>
          </label>
          <div className="max-w-3xl text-center">
            <p className="mt-16 text-2xl sm:text-3xl font-semibold text-white-6 leading-snug">
              {pending
                ? 'Waiting for the confirmation of your subscription'
                : 'Your subscription is now activated'}
            </p>
            <p className="mt-8 mb-12 text-md sm:text-lg text-white-6">
              {pending
                ? 'This should only take a few seconds!'
                : 'Enjoy your experience with Bestofy!'}
            </p>
            {
              <Button
                type="submit"
                className={classNames(
                  'px-8 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2',
                  pending ? 'invisible' : ''
                )}
                onClick={() => navigate('/profile')}
              >
                Go to your profile
              </Button>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Pending;
