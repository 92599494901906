import { Fragment } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { Input } from '../../components';
import { CustomFormErrorMessage } from '.';

const CustomFormTypeEmailConfirm = ({ itemConfig, value, onChange }) => {
  return (
    <>
      <div className="mb-4">
        <Input
          placeholder={(
            'Confirm Email' + (itemConfig.required ? '*' : '')
          )}
          className="w-full border-2 border-black-3 p-0"
          inputClassName="focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-purple-1 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium"
          onChange={(value) => onChange(value)}
          type="email"
          value={value}
        />
      </div>
    </>
  );
};

const CustomFormTypeEmail = ({ itemConfig, value, onChange }) => {
  return (
    <>
      <div className="mb-4">
        <Input
          placeholder={(
            itemConfig.label + (itemConfig.required ? '*' : '')
          )}
          className="w-full border-2 border-black-3 p-0"
          inputClassName="ocus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-purple-1 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium"
          onChange={(value) => onChange(value)}
          type="email"
          value={value}
        />
      </div>
    </>
  );
};

const CustomFormEmail = ({ control, element, errors }) => {
  const email = useWatch({
    control,
    name: 'Email',
    defaultValue: '',
  });
  const emailConfirmation = useWatch({
    control,
    name: 'EmailConfirmation',
    defaultValue: '',
  });
  return (
    <Fragment key="Email">
      <Controller
        name="Email"
        control={control}
        rules={{
          validate: (value) => {
            return value === emailConfirmation ? true : 'Not the same';
          },
          required: true,
        }}
        defaultValue=""
        render={({ field }) => (
          <CustomFormTypeEmail
            itemConfig={element}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {errors.Email?.type === 'validate' && (
        <CustomFormErrorMessage errorMessage={'Email does not match'} />
      )}
      {errors.Email?.type === 'required' && (
        <CustomFormErrorMessage errorMessage={'This field is required'} />
      )}
      <Controller
        name="EmailConfirmation"
        control={control}
        rules={{
          validate: (value) => {
            return value === email ? true : 'Not the same';
          },
          required: true,
        }}
        defaultValue=""
        render={({ field }) => (
          <CustomFormTypeEmailConfirm
            itemConfig={element}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      {errors.EmailConfirmation?.type === 'validate' && (
        <CustomFormErrorMessage errorMessage={'Email does not match'} />
      )}
      {errors.EmailConfirmation?.type === 'required' && (
        <CustomFormErrorMessage errorMessage={'This field is required'} />
      )}
    </Fragment>
  );
};

export default CustomFormEmail;
