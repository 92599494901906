import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Confidentiality from './pages/TermsConditions';
import Contact from './pages/Contact';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Pricing from './pages/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import SignIn from './pages/SignIn';
import Test from './pages/Test';
import SubscriptionTier from './pages/articles/SubscriptionTier';
import ServerDown from './pages/ServerDown';
import ProtectedRoute from './components/ProtectedRoutes';
import Playground from './pages/playground/Playground';
import ProjectExport from './pages/playground/ProjectExport';
import Confirmation from './pages/playground/Confirmation';
import Team from './pages/Team';
import Timeline from './pages/Timeline';
import Pending from './pages/Pending';
import VideoSettingsPage from './pages/playground/VideoSettings';
import PendingGenerating from './pages/playground/PendingGenerating';
import VideoEditing from './pages/playground/VideoEditing';
import ProjectPreview from './pages/playground/ProjectPreview';
import Status from './pages/Status';
import YoutubeRediect from './pages/YoutubeRedirect';
import Automate from './pages/playground/Automate';
import TikTokRedirect from './pages/TikTokRedirect';
import ConfirmationAutomate from './pages/playground/ConfirmationAutomate';
import Notifications from './pages/Notifications';
import Plans from './pages/Plans';
import OrderHistory from './pages/OrderHistory';
import ManagePlan from './pages/ManagePlan';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/subscribe" element={<Pricing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Confidentiality />} />
        <Route path="/auths/twitch/callback" element={<Test />} />
        <Route path="/auths/youtube/callback" element={<YoutubeRediect />} />
        <Route path="/auths/tiktok/callback" element={<TikTokRedirect />} />
        <Route path="/articles/free-tier" element={<SubscriptionTier />} />
        <Route path="/articles/basic-tier" element={<SubscriptionTier />} />
        <Route path="/articles/pro-tier" element={<SubscriptionTier />} />
        <Route path="/team" element={<Team />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/status" element={<Status />} />

        <Route path="/error" element={<ServerDown />} />
        <Route path="/*" element={<NotFound />} />

        <Route path="/playground" element={<ProtectedRoute />}>
          <Route path="/playground" element={<Playground />} />
          <Route path="/playground/video-editing" element={<VideoEditing />} />
          <Route
            path="/playground/video-param"
            element={<VideoSettingsPage />}
          />
          <Route path="/playground/video-export" element={<ProjectExport />} />
          <Route
            path="/playground/video-generation"
            element={<PendingGenerating />}
          />
          <Route path="/playground/confirmation" element={<Confirmation />} />
          <Route
            path="/playground/success"
            element={<ConfirmationAutomate />}
          />
          <Route path="/playground/project" element={<ProjectPreview />} />
          <Route path="/playground/automate" element={<Automate />} />
        </Route>

        <Route path="/profile" element={<ProtectedRoute />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/notifications" element={<Notifications />} />
          <Route path="/profile/plan" element={<Plans />} />
          <Route path="/profile/receipt" element={<OrderHistory />} />
          <Route path="/profile/manage-your-plan" element={<ManagePlan />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
