import { Textarea } from '../../components';

const CustomFormInputTextArea = ({ itemConfig, value, onChange }) => {
  return (
    <div className="mb-4">
      <Textarea
        placeholder={(
          itemConfig.label + (itemConfig.required ? '*' : '')
        )}
        className="w-full border-2 border-black-3 p-0"
        inputClassName="h-40 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-purple-1 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium"
        onChange={(value) => onChange(value)}
        value={value}
      />
    </div>
  );
};

export default CustomFormInputTextArea;
