import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';
import { URL_CONFIDENTIALITY } from './api/routes';

const PrivacyPolicy = () => {
  const [privacy, setPrivacy] = useState([]);
  const navigate = useNavigate();

  const fetchPrivacyData = useCallback(async () => {
    try {
      const response = await axios.get(URL_CONFIDENTIALITY);
      setPrivacy(response.data);
    } catch (error) {
      console.error(error);
      setPrivacy(error.message);
      navigate('/error');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPrivacyData();
  }, [fetchPrivacyData]);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-32 mb-12">
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-2 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="text-xl text-white-0 font-medium">
                Privacy Policy
              </span>
              <div className="mt-12">
                {typeof privacy === 'undefined' ? (
                  <p className="text-white-1 text-justify">Loading</p>
                ) : (
                  <p className="text-white-1 text-justify">{privacy}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default PrivacyPolicy;
