import React, { useState, useEffect } from 'react';
import { Navbar } from '../../components';
import { FooterLinks } from '../../containers';
import { Button, DateSelection } from '../../components';
import { format } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {
  URL_PROJECTS,
  URL_USER_INFO,
  URL_YOUTUBE_REDIRECT,
  URL_YOUTUBE_UPLOAD,
} from '../api/routes';

const VideoPublication = ({
  videoData,
  setDisplayCalendar,
  displayCalendar,
  setSelectedDate,
  selectedDate,
  handlePublishNow,
  publishButtonText,
  isYoutubeLinked,
  setVideoDescription,
}) => {
  // Convert length to minutes and seconds
  const [minutes, seconds] = videoData.length.split(':');
  const formattedLength = `${minutes} minutes ${seconds} seconds`;

  // Format created at date
  const formattedCreatedAt = new Date(videoData.createdAt).toLocaleDateString(
    'en-US',
    { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  );

  const handleDateSelect = (date) => {
    setSelectedDate(format(new Date(date[0]), 'MMMM d, yyyy'));
    setDisplayCalendar(false);
  };

  const handleLinkYoutube = async (name) => {
    try {
      const response = await axios.get(URL_YOUTUBE_REDIRECT);
      const { link } = response.data;

      // Redirect to YouTube OAuth
      window.location.href = link;
    } catch (error) {
      console.error('Failed to fetch YouTube OAuth URL', error);
    }
  };

  const downloadVideo = async () => {
    if (videoData.videoUrl) {
      try {
        const response = await fetch(videoData.videoUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${videoData.title}.mp4`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };

  return (
    <div className="mt-10">
      <div className="w-full mb-8">
        <video
          src={videoData.videoUrl}
          controls
          className="w-full h-auto"
        ></video>
        <p className="mt-4 text-white-1 font-medium text-md">
          {formattedLength}
        </p>
        <p className="mt-2 text-white-1 font-medium text-md">
          Generated {formattedCreatedAt}
        </p>
      </div>

      {videoData.isPublished ? (
        <div className="text-white-1 text-md font-medium">
          <p>The video is already published on YouTube.</p>
          <span>
            You can see the video{' '}
            <a
              href={videoData.link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline cursor-pointer font-bold"
            >
              here
            </a>
            .
          </span>
        </div>
      ) : (
        <>
          {isYoutubeLinked && (
            <>
              <div className="text-white-1 text-sm mb-3 ml-1">
                Video description
              </div>
              <textarea
                onChange={(e) => setVideoDescription(e.target.value)}
                defaultValue={videoData.description}
                className="w-full h-40 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-white-1 px-2"
              />
            </>
          )}
          <div className="flex flex-col md:flex-row justify-between md:items-center mb-8 mt-8">
            <div className="mb-4 md:mb-0">
              <Button
                type="submit"
                variant="text"
                onClick={downloadVideo}
                className="py-3 font-semibold rounded-full w-full md:w-auto text-white-4 mr-8 decoration-white-6 decoration-2 underline underline-offset-4 hover:text-purple-1 hover:decoration-purple-1"
              >
                Download Video
              </Button>
            </div>

            {isYoutubeLinked ? (
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="relative mb-4 md:mb-0">
                  {displayCalendar && (
                    <div className="absolute -mt-72 -ml-3">
                      <DateSelection setDate={handleDateSelect} />
                    </div>
                  )}
                  <Button
                    onClick={() => setDisplayCalendar(true)}
                    className="px-16 py-3 font-semibold rounded-full ring-2 ring-purple-1 hover:ring-purple-2 w-full md:w-auto"
                  >
                    Schedule Publication
                  </Button>
                </div>
                <Button
                  onClick={handlePublishNow}
                  className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2 w-full md:w-auto mt-4 md:mt-0"
                >
                  {publishButtonText}
                </Button>
              </div>
            ) : (
              <Button
                className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2 w-full md:w-auto"
                onClick={handleLinkYoutube}
              >
                Link your YouTube Channel
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const ProjectExport = () => {
  const [videoData, setVideoData] = useState({
    videoUrl: '',
    title: '',
    length: '',
    createdAt: '',
  });
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.state.projectId;
  const [cookies] = useCookies(['user']);
  const [isYoutubeLinked, setIsYoutubeLinked] = useState(false);
  const [videoDescription, setVideoDescription] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchVideoUrl() {
      try {
        const config = {
          headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
        };
        const response = await axios.get(
          `${URL_PROJECTS}?id=${projectId}`,
          config
        );
        setVideoData(response.data.data);
        setVideoDescription(response.data.data.description);
      } catch (error) {
        console.error('Failed to fetch video url', error);
        // Handle error
        if (error.response && error.response.status === 403) {
          navigate('/NotFound');
        } else {
          navigate('/error');
        }
      }
    }

    if (projectId) {
      fetchVideoUrl();
    }
  }, [projectId, cookies, navigate]);

  useEffect(() => {
    async function fetchYoutubeStatus() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      try {
        const response = await axios.get(URL_USER_INFO, config);
        setIsYoutubeLinked(response.data.data.oauths.youtube);
      } catch (error) {
        console.error('Failed to fetch YouTube OAuth status', error);
      }
    }
    fetchYoutubeStatus();
  }, [cookies]);

  const handlePublishNow = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${cookies.Bestofy_Token}`,
      },
    };

    const data = {
      s3id: videoData.id,
      name: videoData.title,
      description: videoDescription,
      privacy: selectedDate ? 'private' : 'public',
      videoContent: videoData.videoUrl,
      short: false,
      dateToPublish: selectedDate
        ? new Date(selectedDate).toISOString()
        : undefined,
    };
    console.log(data);
    try {
      navigate('/playground/confirmation');
      const response = await axios.post(URL_YOUTUBE_UPLOAD, data, config);
      console.log('Successfully uploaded to YouTube:', response.data);
    } catch (error) {
      console.error('Failed to upload video to YouTube', error);
      // Handle errors
    }
  };

  const publishButtonText = selectedDate
    ? `Publish on ${selectedDate}`
    : 'Publish Now';

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="text-xl text-white-0 font-medium">
                {videoData.title}
              </span>
              <VideoPublication
                videoData={videoData}
                displayCalendar={displayCalendar}
                setDisplayCalendar={setDisplayCalendar}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                handlePublishNow={handlePublishNow}
                publishButtonText={publishButtonText}
                isYoutubeLinked={isYoutubeLinked}
                setVideoDescription={setVideoDescription}
              />
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default ProjectExport;
