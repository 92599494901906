import { useEffect, useState } from 'react';
import { Button, Navbar } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';
import { CgChevronLeft } from 'react-icons/cg';
import { FcCheckmark } from 'react-icons/fc';
import axios from 'axios';
import { URL_USER_INFO } from './api/routes';
import { useCookies } from 'react-cookie';
import stripeLogo from '../assets/stripeLogo.png';

const FreeTier = {
  name: 'Bestofy Free',
  price: '$0',
  advantages: ['1 video per month', 'Automatic upload on YouTube'],
  color: 'bg-free-1',
  currentColor: 'bg-free-2',
  navigate: '/articles/free-tier',
};

const BasicTier = {
  name: 'Bestofy Basic',
  price: '$15',
  advantages: [
    '4 videos per month',
    'Automatic upload on YouTube',
    'Edit generated videos',
  ],
  color: 'bg-basic-1',
  currentColor: 'bg-basic-2',
  navigate: '/articles/basic-tier',
};

const ProTier = {
  name: 'Bestofy Pro',
  price: '$35',
  advantages: [
    'Unlimited videos',
    'Access to all features',
    'Edit generated videos',
    'Artificial intelligence',
  ],
  color: 'bg-pro-1',
  currentColor: 'bg-pro-2',

  navigate: '/articles/pro-tier',
};

const PlanCheck = ({ advantages }) => {
  return (
    <div className="flex items-center mt-2">
      <FcCheckmark />
      <span className="text-white-1 ml-2 font-medium">{advantages}</span>
    </div>
  );
};

const PlansCard = ({ currentPlan, tier, subscription }) => {
  const navigate = useNavigate();
  return (
    <>
      {currentPlan && (
        <div
          className={`h-8 rounded-t-lg flex items-center px-6 font-semibold text-xs ${tier.currentColor}`}
        >
          Current Plan
        </div>
      )}

      <div
        className={`h-56 flex items-center p-6 ${tier.color} ${
          !currentPlan ? 'rounded-t-lg' : ''
        }`}
      >
        <div className="text-xl font-bold">{tier.name}</div>
      </div>
      <div className="grid grid-cols-2 gap-12 mb-4 p-6 pb-8 bg-black-2 rounded-b-lg">
        <div className="col-span-1">
          {tier.advantages.map((advantage, index) => (
            <PlanCheck key={index} advantages={advantage} />
          ))}
          <div className="mt-4">
            <span
              onClick={() => navigate(tier.navigate)}
              className="text-white-1 cursor-pointer hover:text-purple-1 underline font-medium"
            >
              Learn more about your plan
            </span>
          </div>
        </div>
        <div className="col-span-1">
          <p className="font-bold text-lg text-white-4">Payment</p>
          <p className="mt-4 text-sm text-white-4">
            Your next bill is for{' '}
            <span className="font-bold">{tier.price}</span> on{' '}
            <span className="font-bold">{subscription}</span>.
          </p>
          <div className="flex mt-8 items-center">
            <div className="flex-1">
              <img className="w-16" src={stripeLogo} alt="stripeLogo" />
            </div>
            <p className="mr-20 text-white-6 font-bold hover:text-white-4 cursor-pointer">
              Update
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const ManagePlan = () => {
  const [cookies] = useCookies(['user']);
  const [subscriptionInfo, setSubscriptioInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getSubscriptionInfo() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      return await axios
        .get(URL_USER_INFO, config)
        .then(function (response) {
          setSubscriptioInfo(response.data.data.user.subscription);
        })
        .catch(function (error) {
          console.log(error);
          setSubscriptioInfo(error.message);
          if (error.response.status === 403) {
            navigate('/NotFound');
          } else {
            navigate('/error');
          }
        });
    }
    getSubscriptionInfo();
    window.scrollTo(0, 0);
  }, [cookies, navigate]);

  const isCurrentPlan = (tierName) => subscriptionInfo.type === tierName;

  // Check if subscriptionInfo.end is available before rendering PlansCard
  const renderPlanCard = (tier) => {
    if (subscriptionInfo && subscriptionInfo.end) {
      return (
        <PlansCard
          key={tier.name}
          currentPlan={false}
          tier={tier}
          subscription={subscriptionInfo.end}
        />
      );
    }
    // Alternatively, return a placeholder or null if the data isn't ready
    return <div>Loading...</div>; // or return null;
  };

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-28 mb-16" />
      <div className="flex justify-center">
        <div className="w-[848px] bg-black-1 py-8 px-8 lg:mb-16 lg:rounded-lg pb-16">
          <div
            onClick={() => navigate('/profile')}
            className="cursor-pointer w-12 h-12 flex justify-center items-center rounded-full bg-black-8 hover:bg-black-2 mb-12"
          >
            <CgChevronLeft className="text-white-1 text-xl mr-0.5" />
          </div>

          <div className="my-6 mb-10">
            <span className="text-xxl text-white-0 font-bold">
              Manage your plan
            </span>
          </div>
          {isCurrentPlan('Bestofy Basic') && renderPlanCard(BasicTier)}
          {isCurrentPlan('Bestofy Pro') && renderPlanCard(ProTier)}
          {isCurrentPlan('Bestofy Free') && renderPlanCard(FreeTier)}
          <div className="flex pt-4">
            <Button
              variant="text"
              type="submit"
              className="px-8 py-3 font-semibold rounded-full ring-1 ring-grey-3 hover:ring-white-6"
              onClick={() => navigate('/profile/plan')}
            >
              Change Plan
            </Button>
            <Button
              variant="text"
              type="submit"
              className="px-8 ml-4 py-3 font-semibold rounded-full ring-1 ring-grey-3 hover:ring-white-6"
              onClick={() => navigate('/profile/plan')}
            >
              Cancel Plan
            </Button>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};
export default ManagePlan;
