import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import { URL_STATUS } from './api/routes';

const StatusCard = ({ status, name }) => (
  <div className="flex flex-col justify-between border-2 border-black-1 bg-black-2 rounded-lg p-4 mb-4 h-full">
    <h2 className="text-white-1 text-lg font-semibold">{name}</h2>
    <CircularProgressbar
      className="h-12 w-12 mb-2"
      value={status === 'OK' ? 100 : 0}
      text={status}
      styles={buildStyles({
        textColor: '#fff',
        pathColor: status === 'OK' ? '#32CD32' : '#B22222',
        trailColor: 'transparent',
      })}
    />
  </div>
);

const Status = () => {
  const [statusData, setStatusData] = useState(null);
  const [lastChecked, setLastChecked] = useState(null);

  useEffect(() => {
    const fetchStatusData = async () => {
      try {
        const response = await axios.get(URL_STATUS);
        setStatusData(response.data.data);
        setLastChecked(response.data.date);
      } catch (error) {
        console.error('Error fetching status data', error);
      }
    };

    fetchStatusData();
  }, []);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen pt-28 mb-12">
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 lg:mb-16 lg:rounded-lg text-r px-4 pt-3">
            <div className="bestofy__bar" />
            <span className="text-xl text-white-0 font-medium">
              Status Dashboard
            </span>
            {lastChecked && (
              <p className="text-md text-white-1 mt-6">
                Last checked: {new Date(lastChecked).toLocaleString()}
              </p>
            )}
            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {statusData &&
                Object.entries(statusData).map(([key, value]) => (
                  <StatusCard
                    key={key}
                    name={value.name || key}
                    status={value.status}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Status;
