import React from 'react';
import headerImage from '../../assets/logo_Bestofy.svg';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Tilt } from 'react-tilt';
import './header.css';

const Header = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['Bestofy_Token']);

  const onClickGetStarted = () => navigate('/subscribe');
  const onClickCreateVideo = () => navigate('/playground');

  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 800, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1.3, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: 'cubic-bezier(.03,.98,.52,.99)', // Easing on enter/exit.
  };

  return (
    <div className="bestofy__header section__padding" id="home">
      <div className="bestofy__header-content">
        <h1 className="gradient__text tracking-in-expand">
          Automate
          <br />
          the creation
          <br />
          of your Best of !
        </h1>
        <p className="slide-in-blurred-left">
          <div className="text-white-6">
            Bestofy automates video creation from Twitch lives, condensing
            highlights into personalized videos for amateur content creators,
            saving time and money effortlessly.
          </div>
        </p>

        <div className="bestofy__header-content__input slide-in-blurred-bl">
          {cookies.Bestofy_Token ? (
            <button
              className="hover:bg-purple-2 hover:border-purple-2"
              type="button"
              onClick={onClickCreateVideo}
            >
              Generate Video
            </button>
          ) : (
            <button
              className="hover:bg-purple-2 hover:border-purple-2"
              type="button"
              onClick={onClickGetStarted}
            >
              Get Started
            </button>
          )}
        </div>
      </div>

      <div className="bestofy__header-image bounce-in-top">
        <Tilt options={defaultOptions}>
          <img src={headerImage} alt="headerImage" />
        </Tilt>
      </div>
    </div>
  );
};

export default Header;
