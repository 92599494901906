import { Navbar, SignInWithTwitch } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const navigate = useNavigate();
  const onClickPrivacyPolicy = () => navigate('/privacy-policy');
  const onClickTermsConditions = () => navigate('/terms');

  return (
    <div className="gradient__bg">
      <Navbar></Navbar>
      <div className="pt-32 mb-32 h-screen">
        <div className="mt-12 relative mx-auto w-full max-w-xl h-auto sm:p-0 p-6">
          <div className="relative bg-black-5 rounded-lg shadow border-4 border-purple-2">
            <div className="pt-14 pb-2 px-6 lg:px-8">
              <h3 className="mb-4 text-xxl font-medium text-center gradient__text">
                Join Bestofy
              </h3>
            </div>
            <div className="flex justify-center pb-10">
              <span className="text-white-6 text-md justify-center text-center px-12">
                Sign up with Twitch to link your account and start using
                Bestofy.
              </span>
            </div>
            <div className="flex justify-center pb-14">
              <SignInWithTwitch></SignInWithTwitch>
            </div>
            <div className="flex justify-center pb-6 px-4 sm:px-12">
              <span className="text-black-4 text-center font-light text-tiny sm:text-xs">
                Click "Sign Up" to agree to Bestofy's&nbsp;
                <button
                  className="underline hover:text-black-3"
                  onClick={onClickTermsConditions}
                >
                  Terms of Service
                </button>
                &nbsp;and acknowledge that Bestofy's&nbsp;
                <button
                  className="underline hover:text-black-3"
                  onClick={onClickPrivacyPolicy}
                >
                  Privacy Policy
                </button>
                &nbsp;applies to you.
              </span>
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default SignIn;
