import React from 'react';
import SignInWithTwitch from '../buttons/SignInWithTwitch';
import { useNavigate } from 'react-router-dom';

const ModalSignIn = ({ onClickCloseModal, type }) => {
  const navigate = useNavigate();
  const onClickPrivacyPolicy = () => navigate('/privacy-policy');
  const onClickTermsConditions = () => navigate('/terms');
  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="-mt-36 backdrop-blur justify-center items-center flex overflow-y-auto fixed inset-0 z-50"
    >
      <div className="relative mx-auto w-full max-w-xl h-auto sm:p-0 p-6">
        <div className="relative bg-black-5 rounded-lg shadow border-4 border-purple-2">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-white-6 bg-transparent hover:bg-purple-1 hover:text-black-6 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="authentication-modal"
            onClick={onClickCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="pt-14 pb-2 px-6 lg:px-8">
            <h3 className="mb-4 text-xxl font-medium text-center gradient__text">
              Join Bestofy
            </h3>
          </div>
          <div className="flex justify-center pb-10">
            <span className="text-white-6 text-md justify-center text-center">
              You have to sign up with Twitch to subscribe.
            </span>
          </div>
          <div className="flex justify-center pb-14">
            <SignInWithTwitch></SignInWithTwitch>
          </div>
          <div className="flex justify-center pb-6 px-4 sm:px-12">
            <span className="text-black-4 text-center font-light text-tiny sm:text-xs">
              Click "Sign Up" to agree to Bestofy's&nbsp;
              <button
                className="underline hover:text-black-3"
                onClick={onClickTermsConditions}
              >
                Terms of Service
              </button>
              &nbsp;and acknowledge that Bestofy's&nbsp;
              <button
                className="underline hover:text-black-3"
                onClick={onClickPrivacyPolicy}
              >
                Privacy Policy
              </button>
              &nbsp;applies to you.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSignIn;
