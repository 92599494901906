import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components';

const ServerDown = () => {
  const navigate = useNavigate();

  return (
    <section className="flex items-center h-screen p-12 gradient__bg">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-black-6">
            503<span className="sr-only">Service Unavailable</span>
          </h2>
          <p className="text-3xl font-semibold text-white-6 leading-snug">
            We apologize for the inconvenience
          </p>
          <p className="mt-8 mb-12 text-white-6">
            Our website is currently experiencing technical difficulties and is
            unavailable at the moment. Our team is working diligently to resolve
            the issue and get the site back up and running as soon as possible.
          </p>
          <Button
            type="submit"
            className="px-8 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
            onClick={() => navigate('/')}
          >
            Back to homepage
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ServerDown;
