import { Radio } from '../../components';

const CustomFormRadio = ({ itemConfig, onChange, value }) => {
  const getListRadio = () => {
    const listRadio = [];
    itemConfig?.listField?.forEach((element) => listRadio.push(element));
    return listRadio;
  };
  const listRadio = getListRadio();

  const onChangeCheck = (value) => {
    onChange(value);
  };

  return (
    <div className="mb-4">
      <label>
        <span className="mt-4 uppercase text-tiny text-white-0 font-medium">
          {itemConfig.label}
        </span>
        {itemConfig?.required && (
          <span className="text-purple-1 ml-3 font-semibold">*</span>
        )}
      </label>
      <div className="p-2">
        <Radio
          names={listRadio}
          onChange={onChangeCheck}
          checkedValue={value}
        />
      </div>
    </div>
  );
};

export default CustomFormRadio;
