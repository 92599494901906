import React, { Fragment, useState } from 'react';
import { Button } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import {
  CustomFormInputTextArea,
  CustomFormErrorMessage,
  CustomFormInput,
  CustomFormCheckbox,
  CustomFormText,
  CustomFormCustomList,
  CustomFormRadio,
  CustomFormEmail,
  CustomFormSucessModal,
} from '.';
import './customForm.css';
import axios from 'axios';
import {
  URL_SEND_MAIL,
  URL_SEND_MAIL_ACKNOWLEDGMENT,
} from '../../pages/api/routes';

const CustomFormFieldFactory = ({ value, onChange, itemConfig }) => {
  switch (itemConfig.type) {
    case 'INPUT_TEXT_AREA':
      return (
        <CustomFormInputTextArea
          itemConfig={itemConfig}
          value={value}
          onChange={onChange}
        />
      );
    case 'INPUT':
      return (
        <CustomFormInput
          itemConfig={itemConfig}
          value={value}
          onChange={onChange}
        />
      );
    case 'CHECKBOX':
      return (
        <CustomFormCheckbox
          itemConfig={itemConfig}
          value={value}
          onChange={onChange}
        />
      );
    case 'CUSTOM_LIST':
      return (
        <CustomFormCustomList
          itemConfig={itemConfig}
          value={value}
          onChange={onChange}
        />
      );
    case 'RADIO':
      return (
        <CustomFormRadio
          itemConfig={itemConfig}
          value={value}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
};

const CustomFormController = ({ config, selectedObject }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const { itemConfigList } = config;
  const [showModalSucess, setShowModalSucess] = useState(false);
  const onClickCloseModal = () => {
    setShowModalSucess(false);
  };

  const onSubmit = (data) => {
    let receiver = [];
    config.customContactObject.forEach((e) => {
      if (e.label === selectedObject) {
        receiver = e.receivers;
      }
    });
    axios({
      method: 'POST',
      url: URL_SEND_MAIL,
      data: {
        formValues: data,
        receiver: receiver,
        selectedObject: selectedObject,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          axios({
            method: 'POST',
            url: URL_SEND_MAIL_ACKNOWLEDGMENT,
            data: { recipient: data.Email },
          }).then(() => {
            if (response.status !== 200) {
              alert('Message failed to send.');
              reset();
            }
          });
        } else if (response.status !== 200) {
          alert('Message failed to send.');
          reset();
        }
      })
      .finally(() => {
        setShowModalSucess(true);
        reset();
      });
  };

  const isDisplayed = (itemConfig) => {
    let displayField = false;
    if (itemConfig.allObjects) {
      displayField = true;
    } else {
      itemConfig.customObjects.forEach((element) => {
        element === selectedObject && (displayField = true);
      });
    }
    return displayField;
  };

  const defaultValueSpecialCase = (itemConfig) => {
    if (itemConfig.type === 'CUSTOM_LIST') {
      return itemConfig?.listField[0];
    } else if (itemConfig.type === 'CHECKBOX') {
      return false;
    } else {
      return '';
    }
  };

  const formInputs = itemConfigList.map((element) => {
    if (isDisplayed(element)) {
      if (element.type === 'TEXT') {
        return (
          <CustomFormText
            key={element.label.replace("'", ' ')}
            itemConfig={element}
          />
        );
      } else if (element.type === 'EMAIL') {
        return (
          <CustomFormEmail
            key={'customEmail'}
            control={control}
            element={element}
            errors={errors}
          />
        );
      } else {
        return (
          <Fragment key={element.label.replace("'", ' ')}>
            <Controller
              name={element.label.replace("'", ' ')}
              control={control}
              rules={element.required && { required: true }}
              defaultValue={defaultValueSpecialCase(element)}
              render={({ field }) => (
                <CustomFormFieldFactory
                  value={field.value}
                  onChange={field.onChange}
                  itemConfig={element}
                />
              )}
            />
            {errors[element.label.replace("'", ' ')] && (
              <CustomFormErrorMessage errorMessage={'This field is required'} />
            )}
          </Fragment>
        );
      }
    } else {
      return null;
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formInputs}
        <div className="pb-8 pt-8 flex justify-center">
          <Button
            type="submit"
            className="bg-purple-1 hover:bg-purple-2 font-medium text-sm rounded-full sm:px-36 px-16 py-3"
          >
            {config.buttonSubmitLabel}
          </Button>
        </div>
      </form>
      {showModalSucess && (
        <CustomFormSucessModal
          onClickCloseModal={onClickCloseModal}
        ></CustomFormSucessModal>
      )}
    </>
  );
};

export default CustomFormController;
