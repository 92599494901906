import React, { useState, useEffect } from 'react';
import { Button, Navbar } from '../components';
import { FooterLinks } from '../containers';
import { CheckBox } from '../components';
import { MdOutlineEmail, MdOutlinePhoneIphone } from 'react-icons/md';
import { CgChevronLeft } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import PushNotifications from '../components/notifications/PushNotifications';
import axios from 'axios';
import { URL_USER_INFO, URL_NOTIFICATION } from './api/routes';
import { useCookies } from 'react-cookie';

const NotificationType = ({
  title,
  subtitle,
  initialMailChecked,
  initialPushChecked,
  onMailChange,
  onPushChange,
  isLoading,
}) => {
  const [firstCheckboxChecked, setFirstCheckboxChecked] =
    useState(initialMailChecked);
  const [secondCheckboxChecked, setSecondCheckboxChecked] =
    useState(initialPushChecked);

  useEffect(() => {
    setFirstCheckboxChecked(initialMailChecked);
    setSecondCheckboxChecked(initialPushChecked);
  }, [initialMailChecked, initialPushChecked]);

  const handleFirstCheckboxChange = (event) => {
    setFirstCheckboxChecked(event.target.checked);
    onMailChange(event.target.checked); // Lifting state up
  };

  const handleSecondCheckboxChange = (event) => {
    setSecondCheckboxChecked(event.target.checked);
    onPushChange(event.target.checked); // Lifting state up
  };

  return (
    <div className="flex justify-between items-center mt-8 w-full">
      <div className="flex flex-col">
        <span className="text-white-1 font-medium">{title}</span>
        <span className="text-white-6 text-sm">{subtitle}</span>
      </div>
      <div className="flex justify-end">
        <div className="w-20 flex flex-col items-center">
          <CheckBox
            checked={firstCheckboxChecked}
            onChange={handleFirstCheckboxChange}
            disabled={isLoading}
          />
        </div>
        <div className="w-20 flex flex-col items-center">
          <CheckBox
            checked={secondCheckboxChecked}
            onChange={handleSecondCheckboxChange}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [cookies] = useCookies(['user']);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [saveSuccess, setSaveSuccess] = useState(false);

  const [notificationPrefs, setNotificationPrefs] = useState({
    mailGeneratedEmail: false,
    mailGeneratedPush: false,
    mailSubscriptionEmail: false,
    mailSubscriptionPush: false,
  });

  useEffect(() => {
    async function getProfileInfo() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      try {
        const response = await axios.get(URL_USER_INFO, config);
        const notificationsSettings =
          response.data.data.user.settings.notifications;

        setNotificationPrefs({
          mailGeneratedEmail: notificationsSettings.mail_generated.active_mail,
          mailGeneratedPush: notificationsSettings.mail_generated.active_push,
          mailSubscriptionEmail:
            notificationsSettings.mail_subscription.active_mail,
          mailSubscriptionPush:
            notificationsSettings.mail_subscription.active_push,
        });
      } catch (error) {
        // Error handling
      }
    }
    getProfileInfo();
    window.scrollTo(0, 0);
  }, [cookies, navigate]);

  const handleNotificationChange = (type, value) => {
    setNotificationPrefs((prevPrefs) => ({
      ...prevPrefs,
      [type]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    setSaveSuccess(false);
    const config = {
      headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
    };
    const body = {
      notifications: {
        mail_subscription: {
          active_mail: notificationPrefs.mailSubscriptionEmail,
          active_push: notificationPrefs.mailSubscriptionPush,
        },
        mail_generated: {
          active_mail: notificationPrefs.mailGeneratedEmail,
          active_push: notificationPrefs.mailGeneratedPush,
        },
      },
    };

    try {
      const response = await axios.put(URL_NOTIFICATION, body, config);
      console.log('Save successful:', response);
      setSaveSuccess(true);
    } catch (error) {
      console.error('Error saving data:', error);
      setSaveSuccess(false);
    } finally {
      setIsLoading(false); // Stop loading regardless of outcome
    }
  };

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-28 mb-12" />
      <div className="flex justify-center">
        <div className="w-[848px] bg-black-1 py-8 px-8 lg:mb-16 lg:rounded-lg pb-16">
          <div
            onClick={() => navigate('/profile')}
            className="cursor-pointer w-12 h-12 flex justify-center items-center rounded-full bg-black-8 hover:bg-black-2 mb-12"
          >
            <CgChevronLeft className="text-white-1 text-xl mr-0.5" />
          </div>
          <span className="text-xxl text-white-0 font-bold">
            Notification settings
          </span>
          {saveSuccess && (
            <div className="flex items-center p-4 font-semibold text-sm text-white-1 bg-purple-1 mt-8 h-14 rounded-lg">
              Notifications saved
            </div>
          )}

          <div className="mt-4">
            <PushNotifications />
          </div>
          <div className="mt-12">
            <div className="text-white-1 font-medium">
              Pick the notifications you want to get via push or email. These
              preferences only apply to push and email.
            </div>
            <div className="flex justify-end mt-4">
              <div className="flex flex-col items-center w-20">
                <MdOutlineEmail className="text-white-1 text-lg" />
                <div className="text-white-1 text-xs mt-1 font-semibold">
                  EMAIL
                </div>
              </div>
              <div className="flex flex-col items-center w-20">
                <MdOutlinePhoneIphone className="text-white-1 text-lg" />
                <div className="text-white-1 text-xs mt-1 font-semibold">
                  PUSH
                </div>
              </div>
            </div>
          </div>
          <div>
            <NotificationType
              title={'Video Generation Notification'}
              subtitle={
                'Receive a notification when a video is done generating'
              }
              initialMailChecked={notificationPrefs.mailGeneratedEmail}
              initialPushChecked={notificationPrefs.mailGeneratedPush}
              onMailChange={(checked) =>
                handleNotificationChange('mailGeneratedEmail', checked)
              }
              onPushChange={(checked) =>
                handleNotificationChange('mailGeneratedPush', checked)
              }
              isLoading={isLoading}
            />
            <NotificationType
              title={'Subscription Renewal'}
              subtitle={'Receive a notification when your subscription renews'}
              initialMailChecked={notificationPrefs.mailSubscriptionEmail}
              initialPushChecked={notificationPrefs.mailSubscriptionPush}
              onMailChange={(checked) =>
                handleNotificationChange('mailSubscriptionEmail', checked)
              }
              onPushChange={(checked) =>
                handleNotificationChange('mailSubscriptionPush', checked)
              }
              isLoading={isLoading}
            />
          </div>
          <div className="flex justify-end mt-12">
            <Button
              className="font-bold hover:text-white-6 cursor-pointer mr-4"
              onClick={() => navigate('/profile')}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className="w-24 px-6 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Notifications;
