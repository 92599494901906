import React from 'react';
import './feature.css';

const Feature = ({ title, text, number }) => (
  <div className="bestofy__features-container__feature">
    <div className="bestofy__features-container__feature-title">
      {number ? (
        <span className="feature-number">{number}</span>
      ) : (
        <div className="gradient-bar" />
      )}
      <h1>{title}</h1>
    </div>
    <div className="bestofy__features-container_feature-text">
      <p>{text}</p>
    </div>
  </div>
);

export default Feature;
