import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import './pages.css';

const Member = ({
  srcImg,
  name,
  title,
  githubLink,
  linkedInLink,
  email,
  second,
}) => {
  return (
    <div
      class={classNames(
        'team__gradient__bg flex flex-col items-center px-4 py-8 transition-colors duration-300 transform border-2 border-black-5 bg-black-1 rounded-lg hover:border-purple-1 hover:bg-black-5 group',
        second && 'xl:col-start-2'
      )}
    >
      <img
        class="object-cover w-32 h-32 rounded-full ring-4 ring-purple-1"
        src={srcImg}
        alt="profilPic"
      />

      <h1 class="mt-8 text-2xl font-semibold text-white-1 capitalize">
        {name}
      </h1>

      <p class="mt-2 text-white-6 capitalize">{title}</p>

      <div class="flex mt-3 -mx-2">
        <a
          href={githubLink}
          class="mx-2 text-white-6 hover:text-purple-1"
          aria-label="Github"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            class="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.026 2C7.13295 1.99937 2.96183 5.54799 2.17842 10.3779C1.395 15.2079 4.23061 19.893 8.87302 21.439C9.37302 21.529 9.55202 21.222 9.55202 20.958C9.55202 20.721 9.54402 20.093 9.54102 19.258C6.76602 19.858 6.18002 17.92 6.18002 17.92C5.99733 17.317 5.60459 16.7993 5.07302 16.461C4.17302 15.842 5.14202 15.856 5.14202 15.856C5.78269 15.9438 6.34657 16.3235 6.66902 16.884C6.94195 17.3803 7.40177 17.747 7.94632 17.9026C8.49087 18.0583 9.07503 17.99 9.56902 17.713C9.61544 17.207 9.84055 16.7341 10.204 16.379C7.99002 16.128 5.66202 15.272 5.66202 11.449C5.64973 10.4602 6.01691 9.5043 6.68802 8.778C6.38437 7.91731 6.42013 6.97325 6.78802 6.138C6.78802 6.138 7.62502 5.869 9.53002 7.159C11.1639 6.71101 12.8882 6.71101 14.522 7.159C16.428 5.868 17.264 6.138 17.264 6.138C17.6336 6.97286 17.6694 7.91757 17.364 8.778C18.0376 9.50423 18.4045 10.4626 18.388 11.453C18.388 15.286 16.058 16.128 13.836 16.375C14.3153 16.8651 14.5612 17.5373 14.511 18.221C14.511 19.555 14.499 20.631 14.499 20.958C14.499 21.225 14.677 21.535 15.186 21.437C19.8265 19.8884 22.6591 15.203 21.874 10.3743C21.089 5.54565 16.9181 1.99888 12.026 2Z"></path>
          </svg>
        </a>

        <a
          href={linkedInLink}
          class="mx-2 text-white-6 hover:text-purple-1"
          aria-label="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3333 3333"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            class="w-6 h-6 fill-current"
          >
            <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-215 1336h342v175h5c48-86 164-175 338-175 361 0 428 225 428 517v596h-357v-528c0-126-3-288-186-288-186 0-214 137-214 279v537h-357V1336zm-247-309c0 102-83 186-186 186-102 0-186-83-186-186 0-102 83-186 186-186 102 0 186 83 186 186zm-371 309h371v1113H834V1336z" />
          </svg>
        </a>

        <a
          href={'mailto:' + email}
          class="mx-2 text-white-6 hover:text-purple-1"
          aria-label="Email"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            enable-background="new 0 0 1000 1000"
            class="w-6 h-6 fill-current"
          >
            <g>
              <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path d="M4564.4,4999.9C2474.8,4796,759.8,3330.7,243.6,1305.6C133.3,872.7,100,595.9,100,108.9c0-476.6,31.2-736.8,135.3-1163.4c93.7-374.6,189.4-639,362.1-990.7c301.8-611.9,691-1119.7,1192.6-1552.6c722.2-620.2,1581.8-1011.5,2543.3-1157.2c326.8-49.9,1001.1-47.9,1332,0c541.1,81.2,1013.6,228.9,1488.1,462c996.9,491.2,1756.6,1250.8,2247.8,2247.8c233.1,474.5,380.9,947,462,1488.1c47.9,330.9,50,1005.3,0,1332c-83.2,547.4-243.5,1059.4-480.8,1529.7c-730.5,1444.4-2095.8,2428.9-3696.4,2664C5415.6,5008.3,4824.5,5024.9,4564.4,4999.9z M7257.6,1753.1c52-20.8-2.1-64.5-1096.9-874.1C5528,410.7,5003.5,29.8,4995.2,31.9c-8.3,2.1-420.4,303.9-915.8,670.2c-495.3,366.3-1011.5,747.2-1148.9,847.1c-231,170.7-243.5,183.1-197.7,201.9C2803.6,1780.2,7184.7,1780.2,7257.6,1753.1z M3656.9,327.4c607.7-443.3,1130.1-832.5,1161.4-861.6c47.9-43.7,79.1-54.1,168.6-54.1c66.6,0,127,12.5,158.2,35.4c29.1,18.7,586.9,428.7,1238.4,909.5l1186.3,872.1L7576,19.4l4.2-1211.3l-56.2-114.5c-43.7-83.3-85.3-129-160.2-176.9l-102-62.4l-2241.5-6.2l-2241.6-4.2l-112.4,58.3c-81.2,41.6-129,85.3-174.8,160.3l-62.4,99.9L2422.7,0.7l-4.2,1238.4l66.6-52C2522.6,1160,3051.3,772.8,3656.9,327.4z" />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

const TeamMembers = () => {
  return (
    <div class="container px-6 py-10 mx-auto slide-in-bottom-2">
      <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-10 md:grid-cols-2 xl:grid-cols-4">
        <Member
          name={'Nicolas Schneider'}
          title={'Frontend Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C4D03AQHvc0Um5bekPg/profile-displayphoto-shrink_800_800/0/1581534279155?e=2147483647&v=beta&t=wttoTeGWz0nJMrv-FWTDIniE8YhUgu8F2XRWe2CkjUI'
          }
          linkedInLink={'https://www.linkedin.com/in/nicolas-schneider-ns/'}
          githubLink={'https://github.com/SchneiderNicolas'}
          email={'nicolas.schneider@epitech.eu'}
        />
        <Member
          name={'Hugo Jeanningros'}
          title={'AI Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C4D03AQEXoKnXtNdwOQ/profile-displayphoto-shrink_800_800/0/1592384332380?e=2147483647&v=beta&t=RHaUzqe3Dv0lLkmHNznL-JQyJASTbA_S6NM7M6b0QVA'
          }
          linkedInLink={'https://www.linkedin.com/in/hugo-jeanningros/'}
          githubLink={'https://github.com/Hjeanningros'}
          email={'hugo.jeanningros@epitech.eu'}
        />
        <Member
          name={'Jean-Baptiste Roesch'}
          title={'Backend Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C4D03AQEslzbzImBLyw/profile-displayphoto-shrink_800_800/0/1619100200563?e=2147483647&v=beta&t=U_U1blZl-w7oaQKlDGzY5gAE5qpgjYPG588rhuZZ56M'
          }
          linkedInLink={'https://www.linkedin.com/in/jbaptisteroesch/'}
          githubLink={'https://github.com/jbaptisteroesch'}
          email={'jean-baptiste.roesch@epitech.eu'}
        />
        <Member
          name={'Florian Golling'}
          title={'Backend Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C4D03AQHp5tRMGTs-VA/profile-displayphoto-shrink_200_200/0/1620151963087?e=2147483647&v=beta&t=i0JPOau1h30-X2FIS5w9b0oVfJG6_7qCSVSsu9T0keE'
          }
          linkedInLink={
            'https://www.linkedin.com/in/florian-golling-2833b0198/'
          }
          githubLink={'https://github.com/floriangolling'}
          email={'florian.golling@epitech.eu'}
        />
        <Member
          second={true}
          name={'Dmitry Yakovlev'}
          title={'Backend Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C5603AQETVpgglfnEXQ/profile-displayphoto-shrink_800_800/0/1580988697738?e=2147483647&v=beta&t=inCQegXkVSHl_yNIkqdXzo11vW9WDCrGJ-9PBgxOz9E'
          }
          linkedInLink={
            'https://www.linkedin.com/in/dmitry-yakovlev-98baa7196/'
          }
          githubLink={'https://github.com/Dimslepims'}
          email={'dmitry.yakovlev@epitech.eu'}
        />
        <Member
          name={'Loïc Deraze'}
          title={'Frontend Developer'}
          srcImg={
            'https://media.licdn.com/dms/image/C4D03AQG4_ATlReowLQ/profile-displayphoto-shrink_800_800/0/1620656906444?e=2147483647&v=beta&t=oPKtzex9WCRitalTUNgITdX74t0q7UnpeD_-FuMJsfk'
          }
          linkedInLink={
            'https://www.linkedin.com/in/lo%C3%AFc-deraze-2a10941ab/'
          }
          githubLink={'https://github.com/derazeloic'}
          email={'loic.deraze@epitech.eu'}
        />
      </div>
    </div>
  );
};

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-32 mb-12">
        <div className="flex items-center justify-center mb-8">
          <div className="md:w-5/6 w-11/12">
            <h1 className="slide-in-bottom gradient__text md:text-3xl text-xl text-center font-bold">
              Our team of developers
            </h1>
            <TeamMembers />
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Team;
