import React, { useEffect, useState } from 'react';
import { Navbar } from '../../components';
import { FooterLinks } from '../../containers';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_PROJECTS } from '../api/routes';
import { useCookies } from 'react-cookie';
import './videoEditing.css';

const VideoProject = ({ projects }) => {
  const navigate = useNavigate();
  return (
    projects && (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project) => (
          <div
            key={project.id}
            className="border-2 border-black-1 bg-black-2 rounded-lg flex flex-col overflow-hidden transition-colors duration-300 transform hover:border-purple-1 hover:bg-black-5 relative cursor-pointer"
            onClick={() =>
              navigate('/playground/video-export', {
                state: { projectId: project.id },
              })
            }
          >
            <div className="relative">
              <img
                src={project.thumbnail}
                alt={project.title}
                className="w-full object-cover rounded-t-lg"
              />
              <div className="absolute bottom-0 right-0 bg-black-1 rounded-md mb-1 mr-1 text-white-1 px-1.5 py-0.5 text-xs">
                {project.length}
              </div>
            </div>
            <div className="p-4 flex-grow flex flex-col">
              <h3 className="text-white-1 text-md font-semibold line-clamp-2 mb-auto">
                {project.title}
              </h3>
              <div className="mt-2 flex justify-center">
                {project.isGenerating ? (
                  <div className="flex items-center space-x-2 mt-1">
                    <div className="text-white-1 text-sm mr-6">
                      Processing Video
                    </div>
                    <div className="w-12 h-12">
                      <CircularProgressbar
                        value={project.progress}
                        text={`${project.progress}%`}
                        styles={buildStyles({
                          textColor: '#fff',
                          pathColor: '#C27BE8',
                          trailColor: 'transparent',
                        })}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full grid grid-cols-2 gap-4"></div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div
          className="border-2 border-black-5 bg-black-2 rounded-lg flex items-center justify-center cursor-pointer transition-colors duration-300 transform hover:border-purple-1 hover:bg-black-5"
          onClick={() => navigate('/playground/video-param')}
        >
          <span className="text-4xl text-white-1 p-6">+</span>
        </div>
      </div>
    )
  );
};

const ProjectPreview = () => {
  const [projects, setProjects] = useState();
  const [cookies] = useCookies(['user']);
  const navigate = useNavigate();

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
    };

    const getProfileInfo = async () => {
      try {
        const response = await axios.get(URL_PROJECTS, config);
        console.log(response.data.data);
        setProjects(response.data.data);
      } catch (error) {
        console.log(error);
        setProjects(error.message);
        if (error.response && error.response.status === 403) {
          navigate('/NotFound');
        } else {
          navigate('/error');
        }
      }
    };

    // Call immediately once
    getProfileInfo();

    // Set up interval to call it every 5 seconds
    const intervalId = setInterval(() => {
      getProfileInfo();
    }, 5000);

    // Clear interval when component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [cookies, navigate]);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="text-xl text-white-0 font-medium">
                Generated Videos
              </span>
              <div className="mt-10">
                <VideoProject projects={projects} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default ProjectPreview;
