import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';
import { URL_TERMS } from './api/routes';

const TermsConditions = () => {
  const [termsConditions, setTermsConditions] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const response = await axios.get(URL_TERMS);
        setTermsConditions(response.data);
      } catch (error) {
        console.log(error);
        setTermsConditions(error.message);
        navigate('/error');
      }
    };

    fetchTermsConditions();
  }, [navigate]);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-32 mb-12">
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-2 py-8 lg:mb-16 lg:rounded-lg text-r px-4 pt-3">
            <div className="bestofy__bar" />
            <span className="text-xl text-white-0 font-medium">
              Terms & Conditions
            </span>
            <div className="mt-12">
              {termsConditions === null ? (
                <p className="text-white-1 text-justify">Loading</p>
              ) : (
                <p className="text-white-1 text-justify">{termsConditions}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default TermsConditions;
