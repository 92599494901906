import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatBestofy.css';

const WhatBestofy = () => {
  return (
    <div className="bestofy__whatbestofy section__margin" id="wbestofy">
      <div className="bestofy__whatbestofy-feature">
        <Feature
          title="Advanced AI"
          text="Bestofy uses advanced AI to analyze Twitch streams, identifying key moments for easy, quick video creation without needing extensive editing skills or technical knowledge, showcasing users' content effectively."
        />
      </div>
      <div className="bestofy__whatbestofy-heading">
        <h1 className="gradient__text">
          The possibilities are beyond your imagination
        </h1>
      </div>
      <div className="bestofy__whatbestofy-container">
        <Feature
          title="Generate"
          text="Generate a personalized best-of from Twitch live streams in just a few clicks, no technical skills needed!"
          number={false}
        />
        <Feature
          title="Modify"
          text="Easily customize your generated video with just a few clicks. Make it truly your own and stand out from the crowd."
          number={false}
        />
        <Feature
          title="Publish"
          text="Automatic video publication allows you to focus on live content. With a click, your video is published and ready for sharing."
          number={false}
        />
      </div>
    </div>
  );
};

export default WhatBestofy;
