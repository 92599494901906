import { useEffect, useState } from 'react';
import { ModalSignIn, Navbar } from '../components';
import PricingDetails from '../components/pricingTable/PricingDetails';
import PricingTable from '../components/pricingTable/PricingTable';
import { FooterLinks } from '../containers';

const Pricing = () => {
  const [showModalSignIn, setShowModalSignIn] = useState(false);
  const [pricingType, setPricingType] = useState('monthly');

  const onClickSignIn = () => setShowModalSignIn((prev) => !prev);
  const onClickCloseModal = () => setShowModalSignIn(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-32 mb-12">
        <div className="flex items-center justify-center mb-8">
          <div className="md:w-5/6 w-11/12">
            <h1 className="gradient__text md:text-3xl text-xl text-center font-bold">
              Find the right plan for you and your community.
            </h1>
            <div className="mt-8">
              <PricingTable
                pricingType={pricingType}
                setPricingType={setPricingType}
                onClickSignIn={onClickSignIn}
              />
            </div>
            <div className="mt-28">
              <h2 className="gradient__text md:text-xxl text-lg text-center font-bold">
                Know what's included with each plan.
              </h2>
              <PricingDetails pricingType={pricingType} />
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
      {showModalSignIn && <ModalSignIn onClickCloseModal={onClickCloseModal} />}
    </div>
  );
};

export default Pricing;
