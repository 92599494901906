import React from 'react';
import SocialMediaImage from '../../assets/socialMedia.png';
import './automate.css';

const Automate = () => {
  return (
    <div className="bestofy__automate section__padding" id="automate">
      <div className="bestofy__automate-image">
        <img src={SocialMediaImage} alt="automate" />
      </div>
      <div className="bestofy__automate-content">
        <h1 className="gradient__text">
          Automate the publication
          <br /> of your content on social networks
        </h1>
        <p>
          Bestofy automates video publication on platforms like YouTube Shorts,
          TikTok, and Instagram with one click, eliminating manual uploads and
          saving time.
          <br />
          Elevate your content creation effortlessly with Bestofy.
        </p>
      </div>
    </div>
  );
};

export default Automate;
