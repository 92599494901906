import { ChangeEvent, useEffect, useRef, useState } from 'react';
import './checkbox.css';

type CheckboxProps = {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onCheck?: () => void;
  onUncheck?: () => void;
  onClick?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  boxRight?: boolean;
};

const CheckBox = ({
  name,
  checked,
  disabled = false,
  onClick,
  onChange,
  onCheck,
  onUncheck,
  boxRight,
}: CheckboxProps) => {
  const checkbox = useRef<HTMLInputElement>(null);
  const [check, setCheck] = useState(checked ?? false);

  const click = () => {
    if (checkbox.current) {
      setCheck(!checkbox.current.checked);
      if (!checkbox.current.checked) {
        onCheck?.();
      } else {
        onUncheck?.();
      }

      onClick?.();
    }
  };

  const change = (event: ChangeEvent<HTMLInputElement>) => {
    if (checkbox.current) {
      setCheck(checkbox.current.checked);
      if (checkbox.current.checked) {
        onCheck?.();
      } else {
        onUncheck?.();
      }

      onChange?.(event);
    }
  };

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <div className="m-2 flex items-center cursor-pointer" onClick={click}>
      {!boxRight && name && (
        <label className="m-0 mr-2 text-white-0">{name}</label>
      )}
      <div className="relative">
        <input
          ref={checkbox}
          type="checkbox"
          name={name}
          checked={check}
          onChange={change}
          disabled={disabled}
          className="accent-purple-1 w-4 h-4 rounded checkbox-hover"
        />
      </div>
      {boxRight && name && (
        <label className="m-0 ml-2 text-white-0">{name}</label>
      )}
    </div>
  );
};

export default CheckBox;
