import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA4 from 'react-ga4';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

const measurementId = 'G-Y26BJTZSS8';
ReactGA4.initialize(measurementId);

Sentry.init({
  dsn: 'https://bc6c38a7a10549ce99f3b4efba9057c6@o4504861212344320.ingest.sentry.io/4504913182064640',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: window.location.hostname !== 'localhost',
});

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
