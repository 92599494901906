import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Navbar } from '../../components';
import { FooterLinks } from '../../containers';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { URL_AUTOMATE } from '../api/routes';
import { CiYoutube } from 'react-icons/ci';

const AutoPublishSettings = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [publishTime, setPublishTime] = useState('12:00');
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);

  useEffect(() => {
    const dayMappingReverse = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    const fetchData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
        };

        const response = await axios.get(URL_AUTOMATE, config);
        const dataArray = response.data.data;

        if (dataArray) {
          const cronSchedule = dataArray.cronSchedule;
          const services = dataArray.services;

          const [minutes, hours, , , daysInCron] = cronSchedule.split(' ');
          const daysArray = daysInCron
            .split(',')
            .map((day) => dayMappingReverse[day]);

          setSelectedDays(daysArray);
          setSelectedPlatforms(services);
          setPublishTime(`${hours}:${minutes}`);
        }
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
  }, [cookies.Bestofy_Token]);

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const platforms = ['YouTube', 'TikTok', 'Instagram', 'Twitter'];

  const toggleDay = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const togglePlatform = (platform) => {
    setSelectedPlatforms((prev) =>
      prev.includes(platform)
        ? prev.filter((p) => p !== platform)
        : [...prev, platform]
    );
  };

  const convertToCron = (selectedDays, publishTime) => {
    const dayMapping = {
      Monday: '1',
      Tuesday: '2',
      Wednesday: '3',
      Thursday: '4',
      Friday: '5',
      Saturday: '6',
      Sunday: '0',
    };
    const daysInCron = selectedDays.map((day) => dayMapping[day]).join(',');

    const [hours, minutes] = publishTime.split(':');

    return `${minutes} ${hours} * * ${daysInCron}`;
  };

  const handleConfirm = async () => {
    const cronFormat = convertToCron(selectedDays, publishTime);

    try {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      const postData = {
        cronSchedule: cronFormat,
        services: selectedPlatforms,
      };

      const response = await axios.post(URL_AUTOMATE, postData, config);

      console.log(response.data);

      navigate('/playground/success');
    } catch (error) {
      console.error('Failed to post data', error);
      if (error.response && error.response.status === 403) {
        navigate('/NotFound');
      } else {
        navigate('/error');
      }
    }
  };

  return (
    <div className="flex flex-col h-full mt-10">
      <span className="text-sm font-medium text-white-0 mb-6">
        Choose the days to auto-publish:
      </span>
      <div className="flex flex-wrap mb-4">
        {days.map((day) => (
          <Button
            key={day}
            onClick={() => toggleDay(day)}
            className={`m-2 rounded-full ${
              selectedDays.includes(day)
                ? 'bg-purple-1 hover:bg-purple-2 active-pill'
                : 'bg-black-4 hover:bg-black-3'
            }`}
          >
            {day}
          </Button>
        ))}
      </div>
      <span className="mt-6 text-sm font-medium text-white-0 mb-6">
        Select the publishing platforms:
      </span>
      <div className="flex flex-wrap mb-4">
        {platforms.map((platform) => (
          <Button
            key={platform}
            onClick={() => togglePlatform(platform)}
            className={`m-2 rounded-full ${
              selectedPlatforms.includes(platform)
                ? 'bg-purple-1 hover:bg-purple-2 active-pill'
                : 'bg-black-4 hover:bg-black-3'
            }`}
          >
            {platform}
          </Button>
        ))}
      </div>
      <span className="text-sm font-medium text-white-0 mt-6 mb-6">
        Select the publication time:
      </span>
      <div className="mb-4">
        <input
          type="time"
          value={publishTime}
          onChange={(e) => setPublishTime(e.target.value)}
          className="input-time"
        />
      </div>

      <div className="py-8 flex items-center justify-center">
        <Button
          onClick={handleConfirm}
          type="submit"
          className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

const CustomFileInput = ({ setValue, accept, name, buttonLabel }) => {
  const [fileName, setFileName] = useState('');

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setValue(name, file);
    } else {
      setFileName('');
    }
  };

  return (
    <div className="flex items-center mb-5 md:mb-0">
      <input
        type="file"
        accept={accept}
        id={name}
        className="hidden"
        onChange={handleChange}
      />
      <label
        htmlFor={name}
        className="w-auto h-10 px-6 py-3 font-semibold rounded-md bg-purple-1 hover:bg-purple-2 text-xs text-white-0 cursor-pointer flex items-center justify-center"
      >
        {buttonLabel}
      </label>
      <div className="ml-4 text-xs font-medium text-purple-1">
        {fileName || 'No file chosen'}
      </div>
    </div>
  );
};

const PublishClip = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const platforms = ['YouTube', 'TikTok', 'Instagram', 'Twitter'];
  const navigate = useNavigate();

  const handleVideoChange = (name, file) => {
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setSelectedVideo(videoURL);
    } else {
      setSelectedVideo(null);
    }
  };

  const togglePlatform = (platform) => {
    setSelectedPlatforms((prev) =>
      prev.includes(platform)
        ? prev.filter((p) => p !== platform)
        : [...prev, platform]
    );
  };

  const handleConfirm = () => {
    console.log('Video confirmed for upload or processing');
    console.log(selectedPlatforms + selectedVideo);
    navigate('/playground/success');
  };

  return (
    <div className="flex flex-col h-full mt-10">
      <div className="flex justify-between items-center">
        {/* CustomFileInput component on the left */}
        <CustomFileInput
          setValue={handleVideoChange}
          accept="video/*"
          name="videoClip"
          buttonLabel="Select Video"
        />

        {/* Platform Selection on the right */}
        <div className="flex items-center">
          <span className="text-sm font-medium text-white-0 mr-6 ml-2">
            Publish on:
          </span>
          <div className="flex flex-wrap">
            {platforms.map((platform) => (
              <Button
                key={platform}
                onClick={() => togglePlatform(platform)}
                className={`m-2 rounded-full ${
                  selectedPlatforms.includes(platform)
                    ? 'bg-purple-1 hover:bg-purple-2 active-pill'
                    : 'bg-black-4 hover:bg-black-3'
                }`}
              >
                {platform}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-8 relative" style={{ paddingTop: '56.25%' }}>
        {selectedVideo ? (
          <video
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            controls
          >
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black-2 rounded-lg">
            <CiYoutube size={100} className="text-purple-2" />
            <p className="text-center mt-4 font-semibold text-white-1">
              Import a video
            </p>
          </div>
        )}
      </div>

      {selectedVideo && (
        <div className="py-8 flex items-center justify-center">
          <Button
            onClick={handleConfirm}
            type="submit"
            className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
          >
            Publish content
          </Button>
        </div>
      )}
    </div>
  );
};

const Automate = () => {
  const [showSettings, setShowSettings] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3 flex justify-between items-center">
              <div>
                <div className="bestofy__bar" />
                <span className="text-xl text-white-0 font-medium">
                  {showSettings ? 'Auto-Publish settings' : 'Publish a clip'}
                </span>
              </div>
              <p
                className="text-md text-white-4 mr-8 decoration-white-6 decoration-2 underline underline-offset-4 hover:text-purple-1 hover:decoration-purple-1 cursor-pointer"
                onClick={() => setShowSettings(!showSettings)}
              >
                {showSettings ? 'Publish a clip' : 'Auto-Publish settings'}
              </p>
            </div>
            <div className="px-4 pt-3">
              {showSettings ? <AutoPublishSettings /> : <PublishClip />}
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Automate;
