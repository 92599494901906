import React, { useEffect } from 'react';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  IoBulbOutline,
  IoTrophyOutline,
  IoBuildOutline,
  IoPeopleCircleOutline,
  IoMedalOutline,
} from 'react-icons/io5';
import { TbConfetti } from 'react-icons/tb';
import { useResponsive } from '../hooks/useResponsive';

const TimeLineCard = ({
  title,
  date,
  subtitle,
  description,
  icon,
  left,
  finished,
}) => {
  const { isFullScreen } = useResponsive();
  if (!isFullScreen) {
    left = true;
  }
  return (
    <VerticalTimelineElement
      contentStyle={
        left
          ? {
              background:
                'radial-gradient(circle at 100% -50%, rgba(63, 0, 113, 1) 0%, rgba(18, 18, 18, 1) 40%)',
              color: '#FFFFFF',
              boxShadow: 'none',
              border: 'solid #C27BE8',
              textAlign: 'left',
            }
          : {
              background:
                'radial-gradient(circle at 0% -50%, rgba(63, 0, 113, 1) 0%, rgba(18, 18, 18, 1) 40%)',
              color: '#FFFFFF',
              boxShadow: 'none',
              border: 'solid #C27BE8',
              textAlign: 'right',
            }
      }
      contentArrowStyle={{ borderRight: '0px' }}
      date={date}
      iconStyle={
        finished
          ? {
              background: '#47016b',
              color: '#C27BE8',
            }
          : {
              background: '#C27BE8',
              color: '#47016b',
            }
      }
      icon={icon}
    >
      <h3 className="text-lg font-bold text-white-1">{title}</h3>
      <h4 className="text-white-6">{subtitle}</h4>
      <p className="text-white-1">{description}</p>
    </VerticalTimelineElement>
  );
};

const TimelineComp = () => {
  return (
    <VerticalTimeline lineColor={'#C27BE8'}>
      <TimeLineCard
        title={'Moonshot'}
        subtitle={'Epitech'}
        date={'6-16 September 2021'}
        description={
          'Two weeks of ideation, reflection on the project and its viability, creation of the team.'
        }
        icon={<IoBulbOutline />}
        left={true}
        finished={true}
      />
      <TimeLineCard
        title={'Forward'}
        subtitle={'Strasbourg'}
        date={'6-17 December 2021'}
        description={
          'Two weeks of prototyping and development of the communication and legal aspects of our project.'
        }
        icon={<IoBuildOutline />}
        left={false}
        finished={true}
      />
      <TimeLineCard
        title={'Epitech experience 2022'}
        subtitle={'Strasbourg'}
        date={'29 January 2022'}
        description={
          'Title of the best idea at the Epitech Experience Strasbourg 2022.'
        }
        icon={<IoTrophyOutline />}
        left={true}
        finished={true}
      />
      <TimeLineCard
        title={'Epitech experience 2022'}
        subtitle={'Paris'}
        date={'9 February 2022'}
        description={
          'Presentation of our project to a jury, second best idea at the Epitech Experience Paris 2022.'
        }
        icon={<IoMedalOutline />}
        left={false}
        finished={true}
      />
      <TimeLineCard
        title={'Development'}
        date={'2022'}
        description={
          'Development of our project, concentrating on the generation and automated editing of best-of videos'
        }
        icon={<IoBuildOutline />}
        left={true}
        finished={true}
      />
      <TimeLineCard
        title={'Closed Beta'}
        date={'April 2023'}
        description={
          'Limited testing of our application to gather feedback, identify issues and ensure smoother launch and user experience.'
        }
        icon={<IoPeopleCircleOutline />}
        left={false}
        finished={false}
      />
      <TimeLineCard
        title={'Development'}
        date={'2023'}
        description={
          'Development of our project, concentrating on the implementation of the AI and performances'
        }
        icon={<IoBuildOutline />}
        left={true}
        finished={false}
      />
      <TimeLineCard
        title={'Release'}
        date={'2024'}
        description={'Release of Bestofy to the public'}
        icon={<TbConfetti />}
        left={false}
        finished={false}
      />
    </VerticalTimeline>
  );
};

const Timeline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-32 mb-12">
        <div className="flex items-center justify-center mb-8">
          <div className="md:w-5/6 w-11/12">
            <h1 className="gradient__text md:text-3xl text-xl text-center font-bold">
              Timeline of our project development
            </h1>
            <div className="mt-12"></div>
            <TimelineComp />
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Timeline;
