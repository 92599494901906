import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Navbar, OauthList } from '../components';
import { FooterLinks } from '../containers';
import stripeLogo from '../assets/stripeLogo.png';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { URL_USER_INFO } from './api/routes';
import {
  BsChevronRight,
  BsArrowRight,
  BsEye,
  BsBell,
  BsReceipt,
  BsGem,
  BsGear,
} from 'react-icons/bs';
import { IconContext } from 'react-icons/lib';

const ProfileHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bestofy__bar" />
      <div className="flex justify-between items-center">
        <span className="text-xl text-white-0 font-medium">Profile</span>
        <Button
          type="submit"
          className="px-6 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
          onClick={() => navigate('/playground')}
        >
          Generate
        </Button>
      </div>
    </>
  );
};

const DividerBar = ({ rounded }) => {
  return (
    <div className="col-span-1 flex items-center justify-center ">
      <div
        className={classNames(
          'bg-purple-1 w-0.5 h-full',
          rounded === 'top' && 'rounded-t-lg',
          rounded === 'bottom' && 'rounded-b-lg'
        )}
      ></div>
    </div>
  );
};

const ProfileStatistiques = ({ userInfo }) => {
  return (
    <>
      <div className="p-6 grid grid-cols-11 gap-0">
        <ProfileStatName name={'Video generated with Bestofy'} />
        <DividerBar rounded={'top'} />
        <ProfileStatName name={'Number of clips on Twitch'} />
        <DividerBar rounded={'top'} />
        <ProfileStatName name={'Hours of content'} />
        <ProfileStatNumber number={userInfo?.numberOfGenerated} />
        <DividerBar rounded={'bottom'} />
        <ProfileStatNumber number={userInfo?.numberOfClips} />
        <DividerBar rounded={'bottom'} />
        <ProfileStatNumber number={userInfo?.timeOfContent} />
      </div>
    </>
  );
};

const ProfileStatistiquesMobile = ({ userInfo }) => {
  return (
    <>
      <div className="p-6 grid grid-cols-3 gap-0">
        <ProfileStatMobile
          name={'Video generated with Bestofy'}
          number={userInfo?.numberOfGenerated}
        />
        <DividerHorizontal />
        <ProfileStatMobile
          name={'Number of clips on Twitch'}
          number={userInfo?.numberOfClips}
        />
        <DividerHorizontal />
        <ProfileStatMobile
          name={'Hours of content'}
          number={userInfo?.timeOfContent}
        />
      </div>
    </>
  );
};

const DividerHorizontal = () => {
  return (
    <div className="col-span-3 bg-purple-1 h-0.5 my-2 rounded-l-lg rounded-r-lg" />
  );
};

const ProfileStatMobile = ({ name, number }) => {
  return (
    <div className="col-span-3 flex flex-col justify-center text-center mt-2">
      <p className="text-md text-white-6 medium">{name}</p>
      <p className="text-3xl text-white-4 font-bold">{number}</p>
    </div>
  );
};

const ProfileStatName = ({ name }) => {
  return (
    <div className="sm:col-span-3 flex flex-col justify-center text-center mt-2 mb-2">
      <p className="text-md text-white-6 medium">{name}</p>
    </div>
  );
};

const ProfileStatNumber = ({ number }) => {
  return (
    <div className="col-span-3 text-center">
      <p className="text-3xl text-white-4 font-bold">{number}</p>
    </div>
  );
};

const ProfileName = ({ userInfo }) => {
  return (
    <div className="my-4">
      <div className="flex flex-wrap justify-center">
        <div className="w-6/12 sm:w-6/12">
          <img
            src={
              userInfo?.profilePicture
                ? userInfo?.profilePicture
                : 'https://static-cdn.jtvnw.net/user-default-pictures-uv/294c98b5-e34d-42cd-a8f0-140b72fba9b0-profile_image-300x300.png'
            }
            alt="profilePicture"
            className="rounded-full max-w-full h-auto align-middle border-4 border-purple-1"
          />
        </div>
      </div>
      <div className="mt-2 text-center text-xl text-white-4 font-semibold">
        {userInfo?.username}
      </div>
    </div>
  );
};

/*
  const CTABestof = () => {
    const navigate = useNavigate();
    const onClickGenerate = () => navigate('/playground');

    return (
      <div className="py-6 items-center grid grid-cols-3 gap-6">
        <p className="ml-6 text-white-4 text-md font-semibold flow-left col-span-2">
          You can generate a lot of best-of with the current clips of your Twitch
          channel
        </p>
        <div className="col-span-1 text-center heartbeat_slow">
          <Button
            type="submit"
            className="px-8 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
            onClick={onClickGenerate}
          >
            Generate
          </Button>
        </div>
      </div>
    );
  };
  */

const CTAProjects = () => {
  const navigate = useNavigate();
  const onClickProject = () => navigate('/playground/project');

  return (
    <div className="py-6 items-center grid grid-cols-1 sm:grid-cols-3 gap-6">
      <p className="px-6 sm:px-0 text-center sm:text-left sm:ml-6 text-white-6 text-md font-semibold flow-left sm:col-span-2">
        Browse your Bestofy videos and track your latest project's progress
      </p>
      <div className="text-center sm:col-span-1">
        <Button
          variant="text"
          type="submit"
          className="px-8 py-3 font-semibold rounded-full ring-2 ring-purple-1 hover:ring-purple-2"
          onClick={onClickProject}
        >
          Projects
        </Button>
      </div>
    </div>
  );
};

const AccountLinks = ({ oauths }) => {
  return (
    <div className="p-6">
      <div className="bestofy__bar" />
      <p className="mt-2 text-white-4 font-medium text-md">Connections</p>
      <p className="text-white-6">
        Link accounts to enhance your Bestofy experience
      </p>
      <OauthList oauthStatuses={oauths} />
    </div>
  );
};

const AccountSubscription = ({ subscriptionInfo }) => {
  const navigate = useNavigate();
  const onClickLearnMore = (tier) => navigate(`/articles/${tier}`);

  const getRedirectTier = (type) => {
    switch (type) {
      case 'Bestofy Free':
        return 'free-tier';
      case 'Bestofy Basic':
        return 'basic-tier';
      case 'Bestofy Pro':
        return 'pro-tier';
      default:
        return 'free-tier';
    }
  };

  return (
    <div className="p-6">
      <div className="bestofy__bar" />
      <p className="mt-2 text-white-4 font-medium text-md">Your plan</p>
      <p className="text-white-4 text-lg font-bold">
        {subscriptionInfo?.type} Tier
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-32 mt-4 mb-4">
        <div>
          <p className="mt-4 text-white-6">
            Up to 4 videos per month and access to the best benefits of Bestofy,
            at a special price.
          </p>
          <p
            className="mt-6 text-xs text-white-1 decoration-white-6 decoration-2 underline underline-offset-4 hover:text-purple-1 hover:decoration-purple-1 cursor-pointer"
            onClick={() =>
              onClickLearnMore(getRedirectTier(subscriptionInfo?.type))
            }
          >
            Learn more about your plan
          </p>
        </div>
        <div>
          <p className="font-bold text-lg text-white-4">Payment</p>
          <p className="mt-4 text-sm text-white-4">
            Your next bill is for{' '}
            <span className="font-bold">
              {parseInt(subscriptionInfo?.price / 100, 10).toLocaleString(
                'en-US',
                { style: 'currency', currency: 'USD' }
              )}
            </span>{' '}
            on <span className="font-bold">{subscriptionInfo?.end}</span>.
          </p>
          <div className="flex mt-8 items-center">
            <img className="w-16" src={stripeLogo} alt="stripeLogo" />
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingsButton = ({ name, onClick, leftIcon }) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer w-full bg-black-2 hover:bg-black-8 rounded-lg flex items-center p-3"
    >
      {leftIcon && (
        <div className="text-white-4 bg-black-9 p-2 rounded-lg mr-4">
          {leftIcon}
        </div>
      )}
      <span className="text-white-2">{name}</span>
      <IconContext.Provider value={{ size: '1em' }}>
        <div className="ml-auto text-white-2">
          <BsChevronRight />
        </div>
      </IconContext.Provider>
    </div>
  );
};

const SecurityAndPrivacy = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [cookie, setCookie, removeCookie] = useCookies(['user']);

  const onClickDisconnect = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('Bestofy_Subscribed');
    removeCookie('Bestofy_Username', { path: '/' });
    removeCookie('Bestofy_Token', { path: '/' });
    navigate('/');
  };

  return (
    <div className="p-6">
      <div className="bestofy__bar" />
      <p className="mt-2 text-white-4 font-medium text-md">
        Security and privacy
      </p>
      <div className="mt-4">
        <SettingsButton
          name={'Notification Settings'}
          onClick={() => navigate('/profile/notifications')}
          leftIcon={<BsBell size={20} />}
        />
        <SettingsButton
          name={'Privacy'}
          onClick={() => navigate('/privacy-policy')}
          leftIcon={<BsEye size={20} />}
        />
        <SettingsButton
          name={'Disconnect'}
          onClick={onClickDisconnect}
          leftIcon={<BsArrowRight size={20} />} // Pass the BsArrowRight icon as a prop for the Disconnect button
        />
      </div>
    </div>
  );
};

const Subscription = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <div className="bestofy__bar" />
      <p className="mt-2 text-white-4 font-medium text-md">Subscription</p>
      <div className="mt-4">
        <SettingsButton
          name={'Available plans'}
          onClick={() => navigate('/profile/plan')}
          leftIcon={<BsGem size={20} />}
        />
        <SettingsButton
          name={'Manage your plan'}
          onClick={() => navigate('/profile/manage-your-plan')}
          leftIcon={<BsGear size={20} />}
        />
        <SettingsButton
          name={'Order history'}
          onClick={() => navigate('/profile/receipt')}
          leftIcon={<BsReceipt size={20} />}
        />
      </div>
    </div>
  );
};

const ProfileContent = ({ profileInfo, oauths }) => {
  const { user } = profileInfo;
  return (
    <>
      <div className="mt-10 grid grid-cols-3 gap-6">
        <div className="hidden sm:flex bg-black-2 rounded-lg col-span-3 sm:col-span-2 flex-col justify-center">
          <ProfileStatistiques userInfo={user} />
        </div>
        <div className="sm:hidden bg-black-2 rounded-lg col-span-3 sm:col-span-2 flex flex-col justify-center">
          <ProfileStatistiquesMobile userInfo={user} />
        </div>
        <div className="col-span-3 sm:col-span-1 bg-black-2 rounded-lg flex flex-col justify-center">
          <ProfileName userInfo={user} />
        </div>
        {/*
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <CTABestof />
        </div>
        */}
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <CTAProjects />
        </div>
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <AccountLinks oauths={oauths} />
        </div>
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <AccountSubscription subscriptionInfo={user?.subscription} />
        </div>
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <Subscription />
        </div>
        <div className="col-span-3 bg-black-2 rounded-lg flex flex-col justify-center">
          <SecurityAndPrivacy />
        </div>
      </div>
    </>
  );
};

const Profile = () => {
  const [cookies] = useCookies(['user']);
  const [profileInfo, setProfileInfo] = useState([]);
  const [oauthStatuses, setOauthStatuses] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function getProfileInfo() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      return await axios
        .get(URL_USER_INFO, config)
        .then(function (response) {
          setProfileInfo(response.data.data);
          setOauthStatuses(response.data.data.oauths);
        })
        .catch(function (error) {
          console.log(error);
          setProfileInfo(error.message);
          if (error.response.status === 403) {
            navigate('/NotFound');
          } else {
            navigate('/error');
          }
        });
    }
    getProfileInfo();
    window.scrollTo(0, 0);
  }, [cookies, navigate]);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-28 mb-12"></div>
      <div className="flex justify-center">
        <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
          <div className="text-r px-4 pt-3">
            <ProfileHeader />
            <ProfileContent profileInfo={profileInfo} oauths={oauthStatuses} />
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default Profile;
