import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { URL_USER_SUBSCRIPTION } from '../pages/api/routes';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function checkSubscription() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      return await axios
        .get(URL_USER_SUBSCRIPTION, config)
        .then(function (response) {
          setIsAllowed(response.data.subscription.active);
          localStorage.setItem(
            'Bestofy_Subscribed',
            response.data.subscription.active
          );
          setLoading(false);
        })
        .catch(function (error) {
          error.code === 'ERR_BAD_REQUEST'
            ? setLoading(false)
            : navigate('/error');
        });
    }
    checkSubscription();
  }, [navigate, cookies]);

  return isLoading ? (
    <div className="gradient__bg" />
  ) : isAllowed ? (
    <Outlet />
  ) : (
    <Navigate to="/subscribe" />
  );
};

export default ProtectedRoute;
