import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import './ressourceGallery.css';
import { URL_CLIPS } from '../../pages/api/routes';

const Transition = Array.from({ length: 6 }, (v, i) => ({
  id: i + 1,
  name: ['fade', 'slide', 'zoom', 'rotate', 'flip', 'bounce'][i],
  status: 'purple',
  type: 'transition',
}));

const SwitchClipTransition = ({ setRessourceValue }) => {
  return (
    <div
      className="flex justify-center mb-5 lg:mb-0"
      onChange={(e) => setRessourceValue(e.target.value)}
    >
      <ul className="res-radio-switch js-p-table__switch">
        <li className="res-radio-switch__item">
          <input
            className="res-radio-switch__input sr-only"
            type="radio"
            name="p-table-switch-name"
            id="p-table-switch-1"
            value="clip"
            defaultChecked
          />
          <label className="res-radio-switch__label" htmlFor="p-table-switch-1">
            Clips
          </label>
        </li>

        <li className="res-radio-switch__item">
          <input
            className="res-radio-switch__input sr-only"
            type="radio"
            name="p-table-switch-name"
            id="p-table-switch-2"
            value="transition"
          />
          <label className="res-radio-switch__label" htmlFor="p-table-switch-2">
            Transitions
          </label>
          <div aria-hidden="true" className="res-radio-switch__marker"></div>
        </li>
      </ul>
    </div>
  );
};

const RessourceGallery = ({ setPlaceholder, config, setVideoDisplay }) => {
  const [clips, setClips] = useState([]);
  const [ressource, setRessource] = useState([]);
  const [ressourceValue, setRessourceValue] = useState('clip');
  const [isActive, setIsActive] = useState(false);
  const [selectedClip, setSelectedClip] = useState(null);

  useEffect(() => {
    setIsActive(!isActive);
    if (ressourceValue === 'clip') {
      setRessource(clips);
    } else {
      setRessource(Transition);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clips, ressourceValue]);

  useEffect(() => {
    axios
      .get(`${URL_CLIPS}?user=FrostBloody`, config)
      .then((response) => {
        const updatedData = response.data.data.map((item) => ({
          ...item,
          type: 'clip',
        }));
        setClips(updatedData);
        setRessource(updatedData);
      })
      .catch(console.log);
  }, [config]);

  const handleDragStart = (item, event) => {
    event.dataTransfer.setData('item', JSON.stringify(item));
    setPlaceholder({
      id: 0,
      type: 'placeholder',
    });
  };

  const setUrl = (item) => {
    let newStr = item.thumbnail_url.substring(
      0,
      item.thumbnail_url.indexOf('-preview')
    );
    newStr += '.mp4';
    setVideoDisplay(newStr);
    setSelectedClip(item);
  };

  return (
    <div className="p-4 pt-4 h-full flex flex-col">
      <div className="pb-4">
        <SwitchClipTransition setRessourceValue={setRessourceValue} />
      </div>
      <div className="flex-grow overflow-y-auto overflow-hidden rounded pr-2 scrollbar space-y-3">
        {ressource.map((item, key) => (
          <div key={key} className="space-x-12">
            <div className="flex flex-col">
              <div className="flex justify-center">
                <div
                  className="w-full h-full text-white-0"
                  draggable
                  onDragStart={(event) => handleDragStart(item, event)}
                >
                  {item.type === 'clip' ? (
                    <div
                      onClick={() => setUrl(item)}
                      className={`${
                        selectedClip === item
                          ? 'border-2 border-purple-2 rounded'
                          : ''
                      }`}
                    >
                      <img
                        className="rounded w-full"
                        src={item.thumbnail_url}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-12 rounded bg-black-3">
                      <p>{item.name}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RessourceGallery;
