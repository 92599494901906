import { CheckBox } from '../../components';

const CustomFormCheckbox = ({ onChange, value, itemConfig }) => {
  const onChangeCheck = (e) => {
    onChange(e.target.checked);
  };

  return (
    <>
      <CheckBox
        name={itemConfig.label}
        boxRight={true}
        onChange={onChangeCheck}
        checked={value}
      />
    </>
  );
};

export default CustomFormCheckbox;
