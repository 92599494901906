import { useEffect, useState } from 'react';
import { Navbar } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';
import { CgChevronLeft } from 'react-icons/cg';
import axios from 'axios';
import { URL_USER_INVOICES } from './api/routes';
import { useCookies } from 'react-cookie';

const OrderHistory = () => {
  const [cookies] = useCookies(['user']);
  const [receiptsInfo, setReceiptsInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getProfileInfo() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      return await axios
        .get(URL_USER_INVOICES, config)
        .then(function (response) {
          setReceiptsInfo(response.data.invoices);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setReceiptsInfo(error.message);
          if (error.response.status === 403) {
            navigate('/NotFound'); 
          } else {
            navigate('/error');
          }
        });
    }
    getProfileInfo();
    window.scrollTo(0, 0);
  }, [cookies, navigate]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript Date
    return date.toLocaleDateString(); // Format date as per locale settings
  };

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-28 mb-16" />
      <div className="flex justify-center">
        <div className="w-[848px] bg-black-1 py-8 px-8 lg:mb-16 lg:rounded-lg pb-16">
          <div
            onClick={() => navigate('/profile')}
            className="cursor-pointer w-12 h-12 flex justify-center items-center rounded-full bg-black-8 hover:bg-black-2 mb-12"
          >
            <CgChevronLeft className="text-white-1 text-xl mr-0.5" />
          </div>

          <div className="my-6">
            <span className="text-xxl text-white-0 font-bold">Receipts</span>
          </div>
          <div className="grid grid-cols-12 mt-8">
            <div className="col-span-3 ml-2 text-white-6 text-xs font-semibold">
              Date
            </div>
            <div className="col-span-5 text-white-6 text-xs font-semibold">
              Order number
            </div>
            <div className="col-span-2 text-white-6 text-xs font-bold">
              Price
            </div>
            <div className="col-span-2 text-white-6 text-xs font-bold"></div>
            <div className="border-b col-span-12 border-grey-3 opacity-20 mt-4"></div>
          </div>
          {receiptsInfo.map((receipt, index) => (
            <div key={index} className="grid grid-cols-12 mt-4">
              <div className="col-span-3 ml-2 text-white-1 text-xs font-semibold">
                {formatDate(receipt.created)}
              </div>
              <div className="col-span-5 text-white-6 text-xs font-semibold">
                {receipt.number}
              </div>
              <div className="col-span-2 text-white-6 text-xs font-semibold">
                ${(receipt.amount_due / 100).toFixed(2)}
              </div>
              <div
                onClick={() => window.open(receipt.invoice_pdf)}
                className="col-span-2 text-white-6 underline text-xs cursor-pointer hover:text-purple-1 font-semibold"
              >
                View details
              </div>
              <div className="border-b col-span-12 border-grey-3 opacity-20 mt-4"></div>
            </div>
          ))}
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default OrderHistory;
