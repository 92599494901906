import { useEffect } from 'react';
import { Footer, WhatBestofy, Header, Trailer, Automate } from '../containers';
import { CTA, Navbar } from '../components';
import axios from 'axios';
import { URL_USER_SUBSCRIPTION } from './api/routes';
import { useCookies } from 'react-cookie';

const Home = () => {
  const [{ Bestofy_Token }] = useCookies(['user']);

  useEffect(() => {
    window.scrollTo(0, 0);

    const checkSubscription = async () => {
      const config = {
        headers: { Authorization: `Bearer ${Bestofy_Token}` },
      };
      try {
        const response = await axios.get(URL_USER_SUBSCRIPTION, config);
        localStorage.setItem(
          'Bestofy_Subscribed',
          response.data.subscription.active
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (Bestofy_Token) {
      checkSubscription();
    }
  }, [Bestofy_Token]);

  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <div className="pt-20">
          <Header />
          <WhatBestofy />
        </div>
      </div>
      <div className="gradient__bg-second">
        <Trailer />
        <Automate />
        <CTA />
        {/*<CTA2 />*/}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
