import React from 'react';
import bestofyLogo from '../../assets/logo_Bestofy.svg';
import { useNavigate } from 'react-router-dom';
import './footer.css';

const FooterLinksHome = () => {
  const navigate = useNavigate();
  const onClickPrivacyPolicy = () => navigate('/privacy-policy');
  const onClickTermsConditions = () => navigate('/terms');
  const onClickContact = () => navigate('/contact');
  return (
    <>
      <div className="bestofy__footer-links">
        <div className="bestofy__footer-links_logo">
          <img src={bestofyLogo} alt="bestofy_logo" />
        </div>
        <div className="bestofy__footer-links_div">
          <h4>Links</h4>
          <p className="hover:underline hover:underline-offset-2">
            Social Media
          </p>
          <p className="hover:underline hover:underline-offset-2">Discord</p>
          <p
            className="hover:underline hover:underline-offset-2"
            onClick={onClickContact}
          >
            Contact
          </p>
        </div>
        <div className="bestofy__footer-links_div">
          <h4>Company</h4>
          <p
            className="hover:underline hover:underline-offset-2"
            onClick={onClickTermsConditions}
          >
            Terms & Conditions{' '}
          </p>
          <p
            className="hover:underline hover:underline-offset-2"
            onClick={onClickPrivacyPolicy}
          >
            Privacy Policy
          </p>
        </div>
        <div className="bestofy__footer-links_div">
          <h4>Get in touch</h4>
          <p>4 rue du Dôme</p>
          <p>67000 Strasbourg</p>
          <p>contact@bestofy.fr</p>
        </div>
      </div>

      <div className="bestofy__footer-copyright">
        <p>@2022 Bestofy. All rights reserved.</p>
      </div>
    </>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const onClickContact = () => navigate('/contact');

  return (
    <div className="bestofy__footer section__padding" id="contact">
      <div className="bestofy__footer-heading">
        <h1 className="gradient__text">
          Contact us now for any requests or questions
        </h1>
      </div>

      <div className="bestofy__footer-btn" onClick={onClickContact}>
        <p className="hover:underline hover:underline-offset-4">
          Contact Us Now
        </p>
      </div>
      <FooterLinksHome />
    </div>
  );
};

export default Footer;
