import { Select } from '../../components';

const CustomFormCustomList = ({ itemConfig, value, onChange }) => {
  const getCustomListLabel = () => {
    const listLabel = [];
    itemConfig.listField.forEach((element) => listLabel.push(element));
    return listLabel;
  };
  const listLabel = getCustomListLabel();

  const onChangeValue = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="mb-4">
      <label>
        <span className="mt-4 text-tiny text-white-0 font-medium">
          {itemConfig.label}
        </span>
        {itemConfig?.required && (
          <span className="text-white-0 ml-3 font-semibold">*</span>
        )}
      </label>
      <Select
        className="focus:outline-none border-2 border-black-2 focus:bg-black-3 bg-black-2 w-full font-medium rounded-none lg:text-sm text-xs"
        options={listLabel}
        selectedValue={value}
        onChange={onChangeValue}
      />
    </div>
  );
};

export default CustomFormCustomList;
