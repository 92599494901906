import axios from 'axios';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { URL_TWITCH_CALLBACK, URL_USER_INFO } from './api/routes';

const Test = () => {
  const url = window.location.href;
  const code = url.split('?code=')[1].split('&scope=')[0];
  const [, setCookie] = useCookies(['user']);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(URL_TWITCH_CALLBACK + code)
      .then(function (response) {
        localStorage.setItem('token', response.data.token);
        setCookie('Bestofy_Token', response.data.token, { path: '/' });
        const config = {
          headers: { Authorization: `Bearer ${response.data.token}` },
        };
        axios
          .get(URL_USER_INFO, config)
          .then(function (response) {
            setCookie('Bestofy_Username', response.data.data.user.username, {
              path: '/',
            });
            navigate('/profile');
          })
          .catch(function (error) {
            console.log(error);
            navigate('/error');
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [code, navigate, setCookie]);
  return <></>;
};

export default Test;
