import { Button } from '../../components';

const Modal = ({ onClickCloseModal }) => {
  return (
    <div className="bg-black-0 bg-opacity-50 justify-center items-center flex overflow-y-auto fixed inset-0 z-50">
      <div className="relative mx-auto w-full max-w-md h-auto sm:p-0 p-6">
        <div className="relative bg-black-8 rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-purple-1 bg-transparent hover:bg-black-3 hover:text-purple-2 rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="authentication-modal"
            onClick={onClickCloseModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-6 px-6 lg:px-8">
            <div className="flex justify-center mb-8 text-xl font-medium text-purple-2">
              MESSAGE SENT
            </div>
            <div className="flex text-center justify-center mb-4 text-sm text-white-6">
              Thank you for your message, we will answer you as soon as
              possible!
            </div>
            <div className="pb-8 pt-8 flex justify-center">
              <Button
                type="submit"
                className="uppercase bg-purple-2 hover:bg-purple-2 font-medium text-sm rounded-full sm:px-36 px-16 py-3"
                onClick={onClickCloseModal}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
