import axios from 'axios';
import { URL_TWITCH_REDIRECT } from '../../pages/api/routes';
import './buttons.css';

const SignInWithTwitch = () => {
  async function oauth() {
    return await axios
      .get(URL_TWITCH_REDIRECT, {
        headers: {},
      })
      .then(function (response) {
        window.location.href = response.data.link;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <div className="flex w-3/5 border-purple-1 border-2 rounded-full  hover:border-purple-2">
        <button
          type="submit"
          className="bg-black-5 text-sm sm:text-md font-medium rounded-full py-2 w-full text-purple-1 hover:text-purple-2"
          onClick={oauth}
        >
          Sign up with Twitch
        </button>
      </div>
    </>
  );
};

export default SignInWithTwitch;
