import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Button } from '../../components';
import { FooterLinks } from '../../containers';

const Spinner = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();
  return (
    <section className="flex items-center justify-center h-screen p-12 gradient__bg">
      <div className="container flex flex-col items-center justify-center mt-24 px-5 mx-auto my-8">
        <input type="checkbox" id="check" checked={!pending} name="spinner" />
        <label for="check" name="spinner">
          <div className="check-icon"></div>
        </label>
        <div className="max-w-3xl text-center">
          <p className="mt-16 text-2xl sm:text-3xl font-semibold text-white-6 leading-snug">
            {pending
              ? 'We are publishing your video'
              : 'Your video is now published'}
          </p>
          <p className="mt-8 mb-12 text-md sm:text-lg text-white-6">
            {pending
              ? 'This should take a few seconds!'
              : 'Enjoy your experience with Bestofy!'}
          </p>
          {
            <Button
              type="submit"
              className={classNames(
                'px-8 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2',
                pending ? 'invisible' : ''
              )}
              onClick={() => navigate('/')}
            >
              Go back to Bestofy
            </Button>
          }
        </div>
      </div>
    </section>
  );
};

const Confirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <Spinner />
      </div>
      <FooterLinks />
    </div>
  );
};

export default Confirmation;
