import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';

type ButtonProps = ComponentProps<'button'> & {
  label?: string;
  labelClassName?: string;
  variant?: 'text' | 'contained';
  children?: ReactNode;
};

const Button = ({
  onClick,
  className,
  variant = 'text',
  type = 'button',
  label,
  labelClassName,
  style,
  children,
  ...props
}: ButtonProps) => {
  return (
    <>
      {label && <label className={labelClassName}>{label}</label>}
      <button
        className={classNames(
          'text-white-0 py-2 px-4',
          { 'bg-purple-1': variant === 'contained' },
          className
        )}
        style={style}
        type={type}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
