import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navbar, Button, DateSelection } from '../../components';
import { FooterLinks } from '../../containers';
import { BsCalendarWeekFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const CustomFileInput = ({ setValue, accept, name, buttonLabel }) => {
  const [fileName, setFileName] = useState('');

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setValue(name, file);
    } else {
      setFileName('');
    }
  };

  return (
    <div className="w-full flex items-center mb-5 md:mb-0">
      <input
        type="file"
        accept={accept}
        id={name}
        className="hidden"
        onChange={handleChange}
      />
      <label
        htmlFor={name}
        className="w-auto h-10 px-6 py-3 font-semibold rounded-md bg-purple-1 hover:bg-purple-2 text-xs text-white-0 cursor-pointer flex items-center justify-center"
      >
        {buttonLabel}
      </label>
      <div className="ml-4 text-xs font-medium text-purple-1">
        {fileName || 'No file chosen'}
      </div>
    </div>
  );
};

const NumberOfClipsInput = ({ register, updatedField, onChange }) => (
  <div className="relative w-full h-10 mb-5 md:mb-0">
    {updatedField === 'videoLength' && (
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          <span className="text-xs font-medium text-purple-1">≈</span>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <span className="text-xs font-medium text-purple-1">clips</span>
        </div>
      </>
    )}
    <input
      type="number"
      placeholder="Number of Clips"
      {...register('numberOfClips')}
      min="1"
      onChange={onChange}
      className={`w-full h-10 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2 ${
        updatedField === 'videoLength' ? 'pl-6' : 'pl-2'
      }`}
    />
  </div>
);

const VideoLengthInput = ({ register, updatedField, onChange }) => (
  <div className="relative w-full h-10 mb-5 md:mb-0">
    {updatedField === 'numberOfClips' && (
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          <span className="text-xs font-medium text-purple-1">≈</span>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <span className="text-xs font-medium text-purple-1">minutes</span>
        </div>
      </>
    )}
    <input
      type="number"
      placeholder="Video Length (min)"
      {...register('videoLength')}
      min="1"
      step="1"
      onChange={onChange}
      className={`w-full h-10 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2 ${
        updatedField === 'numberOfClips' ? 'pl-6' : 'pl-2'
      }`}
    />
  </div>
);

const VideoSettingsForm = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [date, setDate] = useState(new Date());
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [updatedField, setUpdatedField] = useState('');

  const onClosePopUp = () => {
    setDisplayCalendar(false);
  };

  const onOpenPopUp = () => {
    setDisplayCalendar(true);
  };

  const onSubmit = async (data) => {
    console.log(data);
    generateVideo(data);
  };

  const handleNumberOfClipsChange = (e) => {
    const numOfClips = parseInt(e.target.value);
    if (isNaN(numOfClips)) {
      setValue('videoLength', '', { shouldValidate: true });
      setUpdatedField('');
    } else {
      setValue('videoLength', Math.round(numOfClips * 0.4), {
        shouldValidate: true,
      });
      setUpdatedField('numberOfClips');
    }
  };

  const handleVideoLengthChange = (e) => {
    const length = parseInt(e.target.value);
    if (isNaN(length)) {
      setValue('numberOfClips', '', { shouldValidate: true });
      setUpdatedField('');
    } else {
      setValue('numberOfClips', Math.round(length / 0.4), {
        shouldValidate: true,
      });
      setUpdatedField('videoLength');
    }
  };

  useEffect(() => {
    if (date.length > 1) {
      onClosePopUp();
    }
  }, [date]);

  const navigate = useNavigate();

  function generateVideo(data) {
    navigate('/playground/video-editing', { state: { videoData: data } });
  }

  useEffect(() => {
    const data = {
      title: '2',
      description: '2',
      numberOfClips: '10',
      videoLength: 1,
    };
    navigate('/playground/video-editing', { state: { videoData: data } });
  }, [navigate]);

  return (
    <div className="mt-10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor="title"
          className="block text-xs font-medium text-white-0 mb-2"
        >
          Video Title *
        </label>
        <input
          type="text"
          placeholder="Enter video title"
          {...register('title')}
          className="mb-5 w-full border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2"
        />

        <div className="flex flex-col md:flex-row md:space-x-4 mb-8">
          <div className="w-full">
            <label
              htmlFor="numberOfClips"
              className="block text-xs font-medium text-white-0 mb-2"
            >
              Number of Clips *
            </label>
            <NumberOfClipsInput
              register={register}
              updatedField={updatedField}
              onChange={handleNumberOfClipsChange}
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="videoLength"
              className="block text-xs font-medium text-white-0 mb-2"
            >
              Video Length in minutes *
            </label>
            <VideoLengthInput
              register={register}
              updatedField={updatedField}
              onChange={handleVideoLengthChange}
            />
          </div>

          <div className="w-full">
            <label className="block text-xs font-medium text-white-0 mb-2">
              Time Period
            </label>
            <div className="w-full h-10 mb-5 md:mb-0 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2">
              <div
                onClick={onOpenPopUp}
                className={`flex items-center transition ease-in-out ${
                  date.length > 1 ? 'text-purple-1' : 'text-black-2'
                } hover:text-grey duration-300 cursor-pointer`}
              >
                <BsCalendarWeekFill className="cursor-pointer mr-3" size={16} />
                <div className="w-full max-w-xs overflow-x-auto whitespace-nowrap">
                  {date.length > 1
                    ? 'From ' +
                      date[0].toLocaleDateString('en', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }) +
                      ' to ' +
                      date[1].toLocaleDateString('en', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                    : 'Select a time period'}
                </div>
              </div>
              {displayCalendar && (
                <div className="absolute mt-3 -ml-3">
                  <DateSelection setDate={setDate} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-2 mb-8">
          <CustomFileInput
            setValue={setValue}
            accept="image/*"
            name="logo"
            buttonLabel="Logo"
          />

          <CustomFileInput
            setValue={setValue}
            accept="video/mp4"
            name="intro"
            buttonLabel="Video Intro"
          />

          <CustomFileInput
            setValue={setValue}
            accept="video/mp4"
            name="outro"
            buttonLabel="Video Outro"
          />
        </div>

        <label
          htmlFor="description"
          className="block text-xs font-medium text-white-0 mb-2"
        >
          Video Description *
        </label>
        <textarea
          placeholder="Enter video description"
          {...register('description')}
          className="w-full h-40 border-2 border-black-3 focus:outline-none focus:bg-black-4 bg-black-3 p-2 placeholder-black-2 placeholder-shown:text-sm lg:placeholder-shown:text-xs placeholder-shown:font-medium rounded-none text-xs font-medium text-purple-1 px-2"
        />

        <div className="py-8 flex items-center justify-center">
          <Button
            type="submit"
            className="px-16 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

const VideoSettingsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="text-xl text-white-0 font-medium">
                Video Settings
              </span>
              <VideoSettingsForm />
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default VideoSettingsPage;
