import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Navbar } from '../../components';
import { FooterLinks } from '../../containers';

const PlaygroundChoice = () => {
  const navigate = useNavigate();

  const isAutomateDisabled = false;

  return (
    <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6 cursor-pointer">
      <div
        className="bg-black-2 rounded-lg col-span-1 flex flex-col justify-between p-6 transition-colors duration-300 transform border-2 border-black-5 hover:border-purple-1 hover:bg-black-5"
        onClick={() => navigate('/playground/video-param')}
      >
        <div>
          <h1 className="text-white-1 text-lg font-semibold">
            Generate Best-of Video
          </h1>
          <h2 className="text-md font-medium text-white-1 mt-3">
            Effortlessly turn your livestreams into engaging, condensed videos
          </h2>
          <p className="text-sm text-white-6 mt-3">
            Our platform analyzes your stream's content to create best-of video,
            featuring the most exciting and memorable moments. Share your
            passion with your audience and attract new viewers with captivating,
            easy-to-digest content.
          </p>
        </div>
        <div className="mt-6 flex justify-center mb-2">
          <Button
            type="submit"
            className="px-12 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
          >
            Generate
          </Button>
        </div>
      </div>

      <div
        className={`bg-black-2 rounded-lg col-span-1 flex flex-col justify-between p-6 transition-colors duration-300 transform border-2 ${
          isAutomateDisabled
            ? 'border-black-5 hover:border-gray-600 cursor-not-allowed'
            : 'border-black-5 hover:border-purple-1 hover:bg-black-5'
        } relative`}
        onClick={() => !isAutomateDisabled && navigate('/playground/automate')}
      >
        {isAutomateDisabled && (
          <div className="absolute top-0 left-0 w-full h-full bg-black-5 opacity-50 rounded-lg z-10"></div>
        )}
        <div>
          <h1 className="text-white-1 text-lg font-semibold">
            Automated Clips Sharing
          </h1>
          <h2 className="text-md font-medium text-white-1 mt-3">
            Automatically share short format videos on TikTok, YouTube, and more
          </h2>
          <p className="text-sm text-white-6 mt-3">
            Maximize your online presence by distributing your content across
            multiple platforms. Our automation tools make it easy to create and
            share short videos on TikTok, YouTube Shorts, and other platforms,
            expanding your reach and growing your community.
          </p>
        </div>
        <div className="mt-6 mb-2 flex justify-center">
          <Button
            type="text"
            className="px-12 py-3 font-semibold rounded-full ring-2 ring-purple-1 hover:ring-purple-2 disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-not-allowed"
          >
            Automate
          </Button>
        </div>
      </div>
    </div>
  );
};

const Playground = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="min-h-screen">
        <div className="pt-28 mb-12" />
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="text-xl text-white-0 font-medium">
                Choose Your Action
              </span>
              <PlaygroundChoice />
            </div>
          </div>
        </div>
      </div>

      <FooterLinks />
    </div>
  );
};

export default Playground;
