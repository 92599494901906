import React, { useEffect } from 'react';
import { Navbar } from '../../components';
import { FooterLinks } from '../../containers';
import { useLocation } from 'react-router-dom';

const FreeTierText = () => {
  return (
    <p className="text-white-6 text-justify text-md">
      The Free Tier subscription of Bestofy is the perfect solution for anyone
      looking to get started with creating engaging video content.
      <br />
      <br />
      With this subscription, users are given the ability to create one video
      per month at no cost, making it easy to try out the service and see how it
      works.
      <br />
      <br />
      The Free Tier subscription also includes automatic publishing of the
      generated video on Youtube, allowing users to reach a wider audience and
      share their content with the world. This feature is especially useful for
      content creators who want to get their work out there and start building
      their online following.
      <br />
      <br />
      Overall, the Free Tier subscription offers a great introduction to the
      world of video content creation and is the perfect solution for anyone
      looking to get started or simply see what the service has to offer.
      Bestofy Free Tier is an excellent starting point that won't cost you a
      dime.
      <br />
      So why wait? Sign up today and start creating!
    </p>
  );
};

const BasicTierText = () => {
  return (
    <p className="text-white-6 text-justify text-md">
      The Basic Tier subscription of Bestofy is the perfect solution for anyone
      looking to enhance their content creation.
      <br />
      <br />
      With this subscription, users are given the power to create up to 4 videos
      each month, making it easy to stay on top of their content creation game.
      <br />
      <br />
      Not only will you be able to generate high-quality videos with ease, but
      the subscription also includes automatic publishing of these videos on
      Youtube. This means that you can reach a wider audience and share your
      content with the world without having to worry about the tedious process
      of manual uploads.
      <br />
      <br />
      Moreover, the Basic Tier subscription provides access to the video editing
      feature, allowing users to make any final tweaks or adjustments to their
      videos before they go live. This feature is particularly useful for
      content creators who want to add their own personal touch to their videos
      and ensure that they look and sound their best.
      <br />
      <br />
      Finally, the subscription comes with full access to all of the features of
      the artificial intelligence technology used by the site. This technology
      has been designed to analyze Twitch streams and identify the most exciting
      moments, ensuring that users receive the best possible results each and
      every time they create a video.
      <br />
      <br />
      Overall, the Basic Tier subscription offers an all-in-one solution for
      anyone looking to create high-quality videos from their favorite Twitch
      streams.
      <br />
      <br />
      Bestofy Basic Tier has everything you need to elevate your content to the
      next level.
      <br />
      So why wait? Sign up today and start creating!
    </p>
  );
};

const ProTierText = () => {
  return (
    <p className="text-white-6 text-justify text-md">
      The Pro Tier subscription of Bestofy is a must-have for content creators
      who demand the best.
      <br />
      <br />
      With this subscription, users are given unlimited access to the site's
      powerful video editing tool and artificial intelligence technology, making
      it easy to create professional-quality videos with ease.
      <br />
      <br />
      The video editing tool provides users with complete control over their
      videos, allowing them to make final tweaks and adjustments to ensure that
      their content looks and sounds its best. Whether you're looking to add
      your own personal touch or make some last-minute edits, the video editing
      tool gives you the flexibility and control you need to make your content
      stand out.
      <br />
      <br />
      The artificial intelligence technology used by the site is unmatched in
      its power and precision. This technology has been designed to analyze
      Twitch streams and identify the most exciting moments, ensuring that users
      receive the best possible results each and every time they create a video.
      Whether you're looking to highlight the most intense moments of a game or
      showcase the best moments of your stream, the artificial intelligence
      technology will help you create videos that are both engaging and
      impactful.
      <br />
      <br />
      In addition to the video editing tool and artificial intelligence
      technology, the Pro Tier subscription also includes automatic publication
      of videos not only on Youtube, but also on popular short-video platforms
      such as Youtube Shorts, Tiktok, and Instagram. This allows content
      creators to reach a wider audience and share their content across multiple
      platforms, increasing their visibility and helping them grow their online
      following.
      <br />
      <br />
      Overall, the Pro Tier subscription is the perfect solution for anyone
      looking to take their content creation to the next level. With unlimited
      access to the site's powerful video editing tool and artificial
      intelligence technology, as well as automatic publication across multiple
      platforms, this subscription provides everything you need to create
      professional-quality videos that will help you stand out from the crowd.
      <br />
      So why wait? Sign up today and start creating!
    </p>
  );
};

const SubscriptionTier = () => {
  const location = useLocation();
  const getName = () => {
    switch (location.pathname) {
      case '/articles/free-tier':
        return 'Free Tier';
      case '/articles/basic-tier':
        return 'Basic Tier';
      case '/articles/pro-tier':
        return 'Pro Tier';
      default:
        return 'Loading';
    }
  };
  const getText = () => {
    switch (location.pathname) {
      case '/articles/free-tier':
        return <FreeTierText />;
      case '/articles/basic-tier':
        return <BasicTierText />;
      case '/articles/pro-tier':
        return <ProTierText />;
      default:
        return <p className="text-white-6 text-justify text-md">Loading</p>;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="gradient__bg">
      <Navbar></Navbar>
      <div className="pt-32 mb-12">
        <div className="flex justify-center">
          <div className="w-full lg:w-9/12 bg-black-1 py-8 px-4 lg:mb-16 lg:rounded-lg">
            <div className="text-r px-4 pt-3">
              <div className="bestofy__bar" />
              <span className="uppercase text-xl text-white-0 font-medium">
                {getName()}
              </span>
              <div className="mt-12 mb-8">{getText()}</div>
            </div>
          </div>
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};

export default SubscriptionTier;
