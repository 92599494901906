import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './cta.css';

const CTA = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);

  const onClickGetStarted = () => navigate('/subscribe');
  const onClickGenerateVideo = () => navigate('/playground/video-param');

  return (
    <div className="bestofy__cta section__padding">
      <div className="bestofy__cta-content">
        <h3>Download Today & start creating your first Best Of video!</h3>
      </div>
      <div className="bestofy__cta-btn">
        {cookies.Bestofy_Token ? (
          <button
            className="hover:bg-black-2"
            type="button"
            onClick={onClickGenerateVideo}
          >
            Generate Video
          </button>
        ) : (
          <button
            className="hover:bg-black-2"
            type="button"
            onClick={onClickGetStarted}
          >
            Get Started
          </button>
        )}
      </div>
    </div>
  );
};

export default CTA;
