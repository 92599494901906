import React from 'react';
import './trailer.css';
import ReactPlayer from 'react-player';

const Trailer = () => {
  return (
    <div className="bestfoy__trailer py-32" id="howitworks">
      <ReactPlayer
        url={
          'https://eip-trailer-bestofy.s3.eu-central-1.amazonaws.com/BestofyTrailer.e3321d4fd579f7040681.mp4'
        }
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Trailer;
