import { useEffect, useState } from 'react';
import { Button, Navbar } from '../components';
import { FooterLinks } from '../containers';
import { useNavigate } from 'react-router-dom';
import { CgChevronLeft } from 'react-icons/cg';
import { FcCheckmark } from 'react-icons/fc';
import axios from 'axios';
import { URL_USER_INFO } from './api/routes';
import { useCookies } from 'react-cookie';

const FreeTier = {
  name: 'Bestofy Free',
  price: '$0',
  advantages: ['1 video per month', 'Automatic upload on YouTube'],
  color: 'bg-free-1',
  currentColor: 'bg-free-2',
  navigate: '/articles/free-tier',
};

const BasicTier = {
  name: 'Bestofy Basic',
  price: '$15',
  advantages: [
    '4 videos per month',
    'Automatic upload on YouTube',
    'Edit generated videos',
  ],
  color: 'bg-basic-1',
  currentColor: 'bg-basic-2',
  navigate: '/articles/basic-tier',
};

const ProTier = {
  name: 'Bestofy Pro',
  price: '$35',
  advantages: [
    'Unlimited videos',
    'Access to all features',
    'Edit generated videos',
    'Artificial intelligence',
  ],
  color: 'bg-pro-1',
  currentColor: 'bg-pro-2',

  navigate: '/articles/pro-tier',
};

const PlanCheck = ({ advantages }) => {
  return (
    <div className="flex items-center mt-2">
      <FcCheckmark />
      <span className="text-white-1 ml-2 font-medium">{advantages}</span>
    </div>
  );
};

const PlansCard = ({ currentPlan, tier, price }) => {
  const navigate = useNavigate();
  return (
    <>
      {currentPlan && (
        <div
          className={`h-8 rounded-t-lg flex items-center px-6 font-semibold text-xs ${tier.currentColor}`}
        >
          Current Plan
        </div>
      )}

      <div
        className={`h-56 flex items-center p-6 ${tier.color} ${
          !currentPlan ? 'rounded-t-lg' : ''
        }`}
      >
        <div className="text-xl font-bold">{tier.name}</div>
      </div>
      <div className="p-6 bg-black-2 rounded-b-lg">
        {tier.advantages.map((advantage, index) => (
          <PlanCheck key={index} advantages={advantage} />
        ))}
        <div className="mt-4">
          <span
            onClick={() => navigate(tier.navigate)}
            className="text-white-1 cursor-pointer hover:text-purple-1 underline font-medium"
          >
            Learn more
          </span>
        </div>
        <div className="flex justify-between items-center mt-6">
          <div>
            <div className="text-white-1 font-bold text-md">{tier.price}</div>
            <div className="text-white-1 font-semibold text-xs">per month</div>
          </div>
          {!currentPlan && (
            <Button
              onClick={() => navigate(tier.navigate)}
              className="w-28 px-6 py-3 font-semibold rounded-full bg-purple-1 hover:bg-purple-2"
            >
              Select
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const Plans = () => {
  const [cookies] = useCookies(['user']);
  const [profileInfo, setProfileInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getProfileInfo() {
      const config = {
        headers: { Authorization: `Bearer ${cookies.Bestofy_Token}` },
      };
      return await axios
        .get(URL_USER_INFO, config)
        .then(function (response) {
          setProfileInfo(response.data.data.user.subscription.type);
        })
        .catch(function (error) {
          console.log(error);
          setProfileInfo(error.message);
          if (error.response.status === 403) {
            navigate('/NotFound');
          } else {
            navigate('/error');
          }
        });
    }
    getProfileInfo();
    window.scrollTo(0, 0);
  }, [cookies, navigate]);

  const isCurrentPlan = (tierName) => profileInfo === tierName;

  const renderPlanCard = (tier) => (
    <PlansCard
      key={tier.name}
      currentPlan={profileInfo === tier.name}
      tier={tier}
    />
  );

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="pt-28 mb-16" />
      <div className="flex justify-center">
        <div className="w-[848px] bg-black-1 py-8 px-8 lg:mb-16 lg:rounded-lg pb-16">
          <div
            onClick={() => navigate('/profile')}
            className="cursor-pointer w-12 h-12 flex justify-center items-center rounded-full bg-black-8 hover:bg-black-2 mb-12"
          >
            <CgChevronLeft className="text-white-1 text-xl mr-0.5" />
          </div>

          {isCurrentPlan('Bestofy Basic') && renderPlanCard(BasicTier)}
          {isCurrentPlan('Bestofy Pro') && renderPlanCard(ProTier)}
          {isCurrentPlan('Bestofy Free') && renderPlanCard(FreeTier)}

          <div className="my-6">
            <span className="text-xxl text-white-0 font-bold">
              Available plans
            </span>
          </div>

          {!isCurrentPlan('Bestofy Basic') && (
            <div className="mt-4">{renderPlanCard(BasicTier)}</div>
          )}
          {!isCurrentPlan('Bestofy Pro') && (
            <div className="mt-4">{renderPlanCard(ProTier)}</div>
          )}
          {!isCurrentPlan('Bestofy Free') && (
            <div className="mt-4">{renderPlanCard(FreeTier)}</div>
          )}
        </div>
      </div>
      <FooterLinks />
    </div>
  );
};
export default Plans;
