import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const onClickHome = () => navigate('/');
  const onClickContact = () => navigate('/contact');
  const onClickPricing = () => navigate('/subscribe');
  const onClickSignIn = () => navigate('/signin');
  const onClickProfile = () => navigate('/profile');
  const onClickTeam = () => navigate('/team');
  const onClickTimeline = () => navigate('/timeline');

  const [cookies] = useCookies(['user']);

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY && window.scrollY > 0) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  });

  const applyAnimationIfRoot = (animationClass) => {
    return location.pathname === '/' ? animationClass : '';
  };

  return (
    <div
      className={`bestofy__navbar fixed z-50 w-full backdrop-blur-xl ${
        !show && 'bestofy__navbar--hidden'
      }`}
    >
      <div className="bestofy__navbar-links">
        <div
          className={`bestofy__navbar-links_logo ${applyAnimationIfRoot(
            'text-focus-in'
          )}`}
        >
          <button onClick={onClickHome}>
            <img src={logo} alt="logo" />
          </button>
        </div>

        <div className="bestofy__navbar-links_container">
          <p
            className={`hover:underline hover:underline-offset-4 ${applyAnimationIfRoot(
              'slide-in-fwd-right-1'
            )}`}
            onClick={onClickHome}
          >
            <div>Home</div>
          </p>
          <p
            className={`hover:underline hover:underline-offset-4 ${applyAnimationIfRoot(
              'slide-in-fwd-right-2'
            )}`}
            onClick={onClickPricing}
          >
            Pricing
          </p>
          <p
            className={`hover:underline hover:underline-offset-4 ${applyAnimationIfRoot(
              'slide-in-fwd-right-3'
            )}`}
            onClick={onClickTeam}
          >
            Team
          </p>
          <p
            className={`hover:underline hover:underline-offset-4 ${applyAnimationIfRoot(
              'slide-in-fwd-right-4'
            )}`}
            onClick={onClickTimeline}
          >
            Timeline
          </p>
          <p
            className={`hover:underline hover:underline-offset-4 ${applyAnimationIfRoot(
              'slide-in-fwd-right-5'
            )}`}
            onClick={onClickContact}
          >
            Contact Us
          </p>
        </div>
      </div>
      <div
        className={`bestofy__navbar-sign ${applyAnimationIfRoot(
          'slide-in-fwd-right-5'
        )}`}
      >
        {cookies.Bestofy_Token ? (
          <button
            className="hover:bg-purple-2"
            type="button"
            onClick={onClickProfile}
          >
            {cookies.Bestofy_Username}
          </button>
        ) : (
          <button
            className="hover:bg-purple-2"
            type="button"
            onClick={onClickSignIn}
          >
            Sign up
          </button>
        )}
      </div>
      <div className="bestofy__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="bestofy__navbar-menu_container scale-up-center">
            <div className="bestofy__navbar-menu_container-links">
              <p onClick={onClickHome}>
                <a href="#home">Home</a>
              </p>
              <p onClick={onClickPricing}>Pricing</p>
              <p onClick={onClickTeam}>Team</p>
              <p onClick={onClickTimeline}>Timeline</p>
              <p onClick={onClickContact}>Contact Us</p>
            </div>
            <div className="bestofy__navbar-menu_container-links-sign">
              {cookies.Bestofy_Token ? (
                <button
                  className="hover:bg-purple-2"
                  type="button"
                  onClick={onClickProfile}
                >
                  {cookies.Bestofy_Username}
                </button>
              ) : (
                <button
                  className="hover:bg-purple-2"
                  type="button"
                  onClick={onClickSignIn}
                >
                  Sign up
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
