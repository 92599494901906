import React from 'react';
import Calendar from 'react-calendar';
import './calendar.css';

const DateSelection = ({ setDate }) => {
  return (
    <div>
      <Calendar onChange={setDate} selectRange={true} />
    </div>
  );
};

export default DateSelection;
